.afspraak_body {
    .breadcrumbs {
        display: none;
    }
    .afspraak__callout {
        position: relative;
        background-image: url('/area/web/default/assets/images/casba-afspraak.png');
        height: 500px;
        @include breakpoint(small only) {
            height: auto;
            background-image: none;
        }
        h1 {
            color: color(base, White);
            font-size: 72px;
            font-weight: bold;
            width: 550px;
            line-height: 1.05;
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1));
            transform: translateY(55%);
            @include breakpoint(small only) {
                font-size: 24px;
                display: block;
                text-align: left;
                width: 100%;
                color: color(base, Black);
                filter: none;
            }
            @include breakpoint(medium) {
                font-size: 55px;
            }
            @include breakpoint(large) {
                font-size: 72px;
            }
        }
        img {
            width: 350px;
            height: auto;
            bottom: -350px;
            position: absolute;
            transform: translateY(-50%);
            @include breakpoint(medium) {
                width: 250px;
                bottom: -250px;
            }
            @include breakpoint(large) {
                width: 350px;
                bottom: -350px;
            }
        }
    }
    .afspraak__content {
        margin: 80px 0;
        @include breakpoint(small only) {
            margin: 40px 0 0;
        }
        ._content-first {
            margin: 0 0 60px;
        }
        ._text {
            h3 {
                font-size: 24px;
                line-height: 30px;
                font-weight: bold;
                margin: 0 0 15px;
                @include breakpoint(small only) {
                    font-size: 20px;
                    margin: 0 0 5px;
                }
            }
        }
        ._image-container {
            display: flex;
            gap: 40px;
            img {
                flex: 1;
                aspect-ratio: 1/1;
                width: 100%;
                height: auto;
            }
        }
    }
    .afspraak__stappen {
        background: color(primary, DarkBlue);
        padding: 50px 0 80px;
        @include breakpoint(small only) {
            margin: 40px 0 0;
        }
        .afspraak_stap {
            @include breakpoint(small only) {
                margin: 0 0 40px;
            }
        }
        h3 {
            color: color(base, White);
            font-size: 40px;
            font-weight: bold;
            text-align: center;
            margin: 0 0 50px;
            @include breakpoint(small only) {
                font-size: 24px;
            }
        }
        h4 {
            font-family: 'Run Wild', sans-serif;
            color: color(base, White);
            font-size: 30px;
            line-height: 46px;
            @include breakpoint(small only) {
                font-size: 20px;
                line-height: 30px;
            }
        }
        h5 {
            color: color(base, White);
            font-size: 30px;
            font-weight: normal;
            line-height: 46px;
            @include breakpoint(small only) {
                font-size: 20px;
                line-height: 30px;
            }
        }
        p {
            color: color(base, White);
            font-weight: 200;
            margin: 10px 0 0;
        }
    }
    .afspraak__widget {
        margin: 60px 0 0;
        h4 {
            font-size: 21px;
            font-weight: bold;
        }
        iframe {
            @include breakpoint(small only) {
                width: 100%;
            }
        }
    }
}
