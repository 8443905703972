._pagination {
    width: 100%;
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        padding: 40px 0 0;
        li {
            span {
                @include transition;
                display: block;
                cursor: pointer;
                width: 22px;
                height: 22px;
                text-align: center;
                line-height: 22px;
                font-size: 16px;
                margin: 0 5px;
                border-radius: 3px;
                background-repeat: no-repeat;
                @include breakpoint(medium down) {
                    font-size: 14px;
                }
                &._current {
                    pointer-events: none;
                    position: relative;
                    &::after {
                        content: '';
                        position: absolute;
                        display: inline-block;
                        left: 20%;
                        bottom: -5px;
                        width: 60%;
                        border-bottom: 3px solid color(primary, DarkBlue);
                    }
                }
                &._next {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='22px' viewBox='0 0 11 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow down%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='%23303030' fill-rule='nonzero' transform='translate(5.500000, 9.000000) rotate(180.000000) translate(-5.500000, -9.000000) ' points='11 1.60909091 9.33161954 0 0 9 9.33161954 18 11 16.3909091 3.33676093 9'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                    background-position: right;
                    padding-right: 95px;
                    display: inline-block;
                    margin: 0 45px 0 20px;
                    @include breakpoint(small only) {
                        text-indent: -9999px;
                        margin: 0;
                        padding: 0;
                    }
                }
                &._prev {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='22px' viewBox='0 0 11 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow down%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='%23303030' fill-rule='nonzero' transform='translate(5.500000, 9.000000) scale(-1, 1) rotate(180.000000) translate(-5.500000, -9.000000) ' points='11 1.60909091 9.33161954 0 0 9 9.33161954 18 11 16.3909091 3.33676093 9'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                    background-position: left;
                    padding-left: 25px;
                    display: inline-block;
                    margin: 0 70px 0 45px;
                    @include breakpoint(small only) {
                        text-indent: -9999px;
                        margin: 0;
                        padding: 0;
                    }
                }
                &._last {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='22px' viewBox='0 0 24 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPage 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='%23303030' fill-rule='nonzero' transform='translate(18.500000, 9.000000) rotate(180.000000) translate(-18.500000, -9.000000) ' points='24 1.60909091 22.3316195 0 13 9 22.3316195 18 24 16.3909091 16.3367609 9'%3E%3C/polygon%3E%3Cpolygon id='Arrow-down' fill='%23303030' fill-rule='nonzero' transform='translate(5.500000, 9.000000) rotate(180.000000) translate(-5.500000, -9.000000) ' points='11 1.60909091 9.33161954 0 0 9 9.33161954 18 11 16.3909091 3.33676093 9'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                    background-position: center;
                }
                &._first {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='22px' viewBox='0 0 24 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPage 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='%23303030' fill-rule='nonzero' transform='translate(18.500000, 9.000000) scale(-1, 1) rotate(180.000000) translate(-18.500000, -9.000000) ' points='24 1.60909091 22.3316195 0 13 9 22.3316195 18 24 16.3909091 16.3367609 9'%3E%3C/polygon%3E%3Cpolygon id='Arrow-down' fill='%23303030' fill-rule='nonzero' transform='translate(5.500000, 9.000000) scale(-1, 1) rotate(180.000000) translate(-5.500000, -9.000000) ' points='11 1.60909091 9.33161954 0 0 9 9.33161954 18 11 16.3909091 3.33676093 9'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                    background-position: center;
                }
                @include breakpoint(small only) {
                    &._last, &._first {
                        display: none;
                    }
                }
            }
        }
    }
}
