* {
    font-size: 16px;
    font-family: "Graphik LCG", sans-serif;
}

html, body {
    color: color(grey, Black);
    scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6 {
    font-style: normal;
    font-weight: normal;
    color: color(grey, Black);
    text-rendering: optimizeLegibility;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
}

a {
    color: color(grey, Black);
    &:hover {
        text-decoration: underline;
    }
}

h1 {
    font-size: 30px;
    line-height: 34px;
    font-weight: 700;
}

h2 {
    font-size: 26px;
    line-height: 30px;
}

p {
    line-height: 21px;
}

.run-wild {
    font-family: 'Run Wild', sans-serif;
}

.off-canvas.position-left:is(.in-canvas-for-medium) {
    @include breakpoint(small only) {
        visibility: hidden;

        &.is-open {
            visibility: visible;
        }
    }
}

.messages .message {
    font-weight: 400;
    font-size: 13px;
    border: 1px solid transparent;
    line-height: 34px;
    padding: 0 10px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 5px;
    @include breakpoint(small only) {
        padding: 8px 12px;
        line-height: 24px;
    }

    &.warning {
        @include messages("warning");
    }

    &.success {
        @include messages("success");
    }

    &.error {
        @include messages("error");
    }
}

img[lazy="loading"],
img[lazy="error"],
     .image__placeholder {
         background-color: color(base, White);
         background-size: 55%;
         background-repeat: no-repeat;
         background-position: center;
         background-image: url("../../default/assets/images/casba.png");
     }

.a_center {
    text-align: center;
}

.a_right {
    text-align: right;
}

@include breakpoint(small only) {
    .no-padding_mobile {
        padding-left: 0;
        padding-right: 0;
        .grid-x.grid-padding-x {
            margin-left: 0;
            margin-right: 0;
            .cell {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.flex {
    display: flex;
}

.home__banner-side {
    z-index: 2;
    position: fixed;
    left: 0;
    nav {
        background: color(base, White);
        width: 200px;
        padding: 15px 10px;
        border-radius: 0 5px 5px 0;
        line-height: 32px;
        .sale a {
            color: color(alert, Error)
        }
        hr {
            margin: 10px 0;
        }
        .submenu__links {
            li {
                margin-left: 1em;
                font-size: 0.9em;
            }
        }
    }
}
