.catalog__product_media-gallery {
    @include breakpoint(medium up) {
        .image-thumbnail {
            border: 1px solid color(grey, SoftGrey);
            cursor: pointer;
            padding: 5px;
            min-height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            img{
                max-height: 40px;
            }
        }
    }
    @include breakpoint(small only) {
        .thumb__holder {
            position: absolute;
            display: inline-block;
            width: 30px;
            height: 30px;
            top: 10%;
            right: 10%;

            border: 1px solid color(grey, SoftGrey);
            background: color(base, White);
            padding: 5px;
            cursor: pointer;

            img {
                aspect-ratio: 1/1;
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }

            &._active:after {
                content: '';
                height: 3px;
                position: absolute;
                bottom: -10px;
                left: 20%;
                right: 20%;
                background-color: color(grey, Black);
            }

            ._zoom {
                display: block;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='26px' height='31px' viewBox='0 0 26 31' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Casba' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Casba_Product_Desktop_V2' transform='translate(-247.000000, -664.000000)'%3E%3Cg id='Main' transform='translate(178.000000, 1.000000)'%3E%3Cg id='Top-left' transform='translate(51.000000, 0.000000)'%3E%3Cg id='Zoom' transform='translate(19.780937, 664.824818)'%3E%3Cpath d='M18.9099998,9.45546875 C18.9099998,14.6771701 14.6780916,18.9109375 9.45499992,18.9109375 C4.23355744,18.9109375 0,14.6771701 0,9.45546875 C0,4.23211806 4.23355744,0 9.45499992,0 C14.6780916,0 18.9099998,4.23211806 18.9099998,9.45546875 Z' id='Stroke-1' stroke='%23303030' stroke-width='2'%3E%3C/path%3E%3Cline x1='13.995625' y1='17.4740625' x2='22.6565625' y2='27.3001823' id='Stroke-3' stroke='%23303030' stroke-width='2'%3E%3C/line%3E%3Cline x1='9.7190625' y1='5.55018229' x2='9.7190625' y2='13.5501823' id='Line-6' stroke='%23989898' stroke-linecap='square'%3E%3C/line%3E%3Cline x1='5.2190625' y1='9.67518229' x2='14.2190625' y2='9.67518229' id='Line-6' stroke='%23989898' stroke-linecap='square'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                width: 100%;
                height: auto;

                &:after {
                    content: '';
                    display: block;
                    padding-bottom: 100%;
                }
            }
        }
    }

    .catalog__product_media-gallery_thumbnails {
        .swiper-container {
            padding: 0 0 18px;

            .swiper-slide {
                height: 72px;
                @media screen and (min-width: 769px) {
                    width: unset;
                }
            }
        }

    }

    .catalog__product_media-gallery_gallery {
        margin: 0 auto;
        position: relative;

        .swiper-pagination {
            z-index: 1;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .image__holder {
            display: block;
            padding: 20px;
            position: relative;

            img {
                aspect-ratio: 1/1;
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
        }

        .slider__controls {
            .slider__control {
                top: 50%;
                transform: translateY(-50%);

                &.swiper-button-prev {
                    left: -134px;
                }

                &.swiper-button-next {
                    right: -50px;
                }
            }
        }
    }


    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .3s linear;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .media__modal {
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        inset: 0;
        z-index: 10;
    }

    .catalog__product_media-gallery_gallery {
        .swiper-wrapper {
            width: 12.5% !important;
        }
    }

    .modal__header {
        h4 {
            color: #7fa7c1;
            padding-top: 1rem;
            padding-left: 1rem;
            font-size: 16px;
            font-weight: 700;
        }
    }

    .modal__wrapper {
        background: white;
        border-radius: 10px;
        inset: 5% 10%;
        position: absolute;
        max-width: 90vw;
        max-height: 90vh;

        .image__holder {
            img {
                @media screen and (max-width: 768px) {

                    max-height: 250px;
                    max-width: 250px;
                }
                @media screen and (min-width: 768px) {
                    max-height: 600px;

                }
            }
        }

        @media screen and (max-width: 768px) {
            inset: 5%;
            max-height: 55vh;
            top: 15%;
        }

        .slider__controls {
            display: block;

            .slider__control {
                @media screen and (max-width: 768px) {
                    &--bg {
                        background: white;
                        border-radius: 100px;
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        z-index: 1;
                        display: flex;
                        justify-content: center;

                        &.left {
                            left: 0;
                            top: 55%;
                        }

                        &.right {
                            right: 0;
                            top: 55%;
                        }
                    }

                    &.swiper-button-next,
                    &.swiper-button-prev {
                        height: 40px;
                        transform: scale(.5);
                        top: 50%;
                    }
                }

                &.swiper-button-next,
                &.swiper-button-prev {
                    top: 50%;
                }

                &.swiper-button-next {
                    right: 7px;
                }

                &.swiper-button-prev {
                    left: 7px;
                }
            }
        }
    }

    .catalog__product_media-gallery_gallery {
        .image__holder {
            img {
                cursor: zoom-in;
            }
        }
    }

    .modal__content {
        align-self: center;
        overflow: hidden;
        margin-top: 2rem;

        .swiper-slide {
            display: flex;
            justify-content: center;
            height: 100%;
        }
    }
}
