.review-rating {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='72px' height='12px' viewBox='0 0 72 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Casba' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Casba_Product_Desktop_V2' transform='translate(-980.000000, -309.000000)' fill='%23DBDBDB'%3E%3Cg id='Main' transform='translate(178.000000, 1.000000)'%3E%3Cg id='Sterren-12' transform='translate(802.000000, 308.000000)'%3E%3Cpolygon id='rating' points='8.8529717 7.40288847 10.3756795 12 6.40669736 9.16153566 2.42964982 12 3.95219306 7.40288847 0 4.57267689 4.88415412 4.57267689 6.40669736 2.27373675e-13 7.92101064 4.57267689 12.8135593 4.57267689'%3E%3C/polygon%3E%3Cpolygon id='rating' points='23.6495819 7.40288847 25.1722897 12 21.2033075 9.16153566 17.22626 12 18.7488032 7.40288847 14.7966102 4.57267689 19.6807643 4.57267689 21.2033075 2.27373675e-13 22.7176208 4.57267689 27.6101695 4.57267689'%3E%3C/polygon%3E%3Cpolygon id='rating' points='38.446192 7.40288847 39.9688999 12 35.9999177 9.16153566 32.0228702 12 33.5454134 7.40288847 29.5932203 4.57267689 34.4773745 4.57267689 35.9999177 2.27373675e-13 37.514231 4.57267689 42.4067797 4.57267689'%3E%3C/polygon%3E%3Cpolygon id='rating' points='53.2428022 7.40288847 54.7655101 12 50.7965279 9.16153566 46.8194803 12 48.3420236 7.40288847 44.3898305 4.57267689 49.2739846 4.57267689 50.7965279 2.27373675e-13 52.3108411 4.57267689 57.2033898 4.57267689'%3E%3C/polygon%3E%3Cpolygon id='rating' points='68.0394124 7.40288847 69.5621202 12 65.593138 9.16153566 61.6160905 12 63.1386337 7.40288847 59.1864407 4.57267689 64.0705948 4.57267689 65.593138 2.27373675e-13 67.1074513 4.57267689 72 4.57267689'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    margin-right: 10px;
    width: 72px;
    height: 12px;
    .rating {
        display: block;
        height: 12px;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='72px' height='12px' viewBox='0 0 72 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Casba' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Casba_Product_Desktop_V2' transform='translate(-980.000000, -309.000000)' fill='%23EDBA34'%3E%3Cg id='Main' transform='translate(178.000000, 1.000000)'%3E%3Cg id='Sterren-12' transform='translate(802.000000, 308.000000)'%3E%3Cpolygon id='rating' points='8.8529717 7.40288847 10.3756795 12 6.40669736 9.16153566 2.42964982 12 3.95219306 7.40288847 0 4.57267689 4.88415412 4.57267689 6.40669736 2.27373675e-13 7.92101064 4.57267689 12.8135593 4.57267689'%3E%3C/polygon%3E%3Cpolygon id='rating' points='23.6495819 7.40288847 25.1722897 12 21.2033075 9.16153566 17.22626 12 18.7488032 7.40288847 14.7966102 4.57267689 19.6807643 4.57267689 21.2033075 2.27373675e-13 22.7176208 4.57267689 27.6101695 4.57267689'%3E%3C/polygon%3E%3Cpolygon id='rating' points='38.446192 7.40288847 39.9688999 12 35.9999177 9.16153566 32.0228702 12 33.5454134 7.40288847 29.5932203 4.57267689 34.4773745 4.57267689 35.9999177 2.27373675e-13 37.514231 4.57267689 42.4067797 4.57267689'%3E%3C/polygon%3E%3Cpolygon id='rating' points='53.2428022 7.40288847 54.7655101 12 50.7965279 9.16153566 46.8194803 12 48.3420236 7.40288847 44.3898305 4.57267689 49.2739846 4.57267689 50.7965279 2.27373675e-13 52.3108411 4.57267689 57.2033898 4.57267689'%3E%3C/polygon%3E%3Cpolygon id='rating' points='68.0394124 7.40288847 69.5621202 12 65.593138 9.16153566 61.6160905 12 63.1386337 7.40288847 59.1864407 4.57267689 64.0705948 4.57267689 65.593138 2.27373675e-13 67.1074513 4.57267689 72 4.57267689'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
}

.review__holder {
    h3 {
        font-size: 20px;
        line-height: 34px;
        font-weight: 600;
    }
    @include breakpoint(medium) {
        .messages {
            max-width: 50%;
        }
    }
    .product__rating-container {
        margin: 0 0 20px;
        .toggle__review-form label {
            font-size: 13px;
            line-height: 20px;
            cursor: pointer;
            font-weight: 500;
            text-decoration: underline;
            margin: 0 0 10px;
        }
        .rating__form {
            padding: 20px 0;
            border-top: 1px solid color(grey, ExtraSoftGrey);
            border-bottom: 1px solid color(grey, ExtraSoftGrey);
            @include breakpoint(medium) {
                max-width: 50%;
            }
            .rating__group-container {
                display: flex;
                label:first-child {
                    line-height: 20px;
                    margin-right: 10px;
                    font-size: 13px;
                }
                .rating__group {
                    display: block;
                    float: left;
                    > input {
                        opacity: 0;
                        position: absolute;
                        + label {
                            height: 26px;
                            width: 24px;
                            display: block;
                            float: right;
                            cursor: pointer;
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='13px' height='12px' viewBox='0 0 13 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Casba' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Casba_Product_Desktop_V2' transform='translate(-980.000000, -309.000000)' fill='%23DBDBDB'%3E%3Cg id='Main' transform='translate(178.000000, 1.000000)'%3E%3Cg id='Sterren-12' transform='translate(802.000000, 308.000000)'%3E%3Cpolygon id='rating' points='8.8529717 7.40288847 10.3756795 12 6.40669736 9.16153566 2.42964982 12 3.95219306 7.40288847 0 4.57267689 4.88415412 4.57267689 6.40669736 2.27373675e-13 7.92101064 4.57267689 12.8135593 4.57267689'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        }
                        &:checked ~ label, & + label:hover ~ label, & + label:hover {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='13px' height='12px' viewBox='0 0 13 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Casba' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Casba_Product_Desktop_V2' transform='translate(-980.000000, -309.000000)' fill='#edba33'%3E%3Cg id='Main' transform='translate(178.000000, 1.000000)'%3E%3Cg id='Sterren-12' transform='translate(802.000000, 308.000000)'%3E%3Cpolygon id='rating' points='8.8529717 7.40288847 10.3756795 12 6.40669736 9.16153566 2.42964982 12 3.95219306 7.40288847 0 4.57267689 4.88415412 4.57267689 6.40669736 2.27373675e-13 7.92101064 4.57267689 12.8135593 4.57267689'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        }
                    }
                }
            }
            .field__group {
                margin: 0 0 10px;
                input {
                    @include breakpoint(medium) {
                        max-width: 70%;
                    }
                }
            }
        }
    }
    .product__review {
        border-bottom: 1px solid color(base, WhiteSecond);
        margin: 0 0 15px;
        max-width: 75%;
        padding: 0 0 15px;
        > ._header {
            display: flex;
            h4 {
                font-weight: 600;
                margin-right: 10px;
                line-height: 20px;
            }
            .review-rating {
                margin: 4px 0;
            }
        }
        ._content {
            font-size: 14px;
            line-height: 22px;
            padding: 10px 0;
        }
        ._footer {
            font-size: 12px;
            color: color(grey, DarkerGrey);
        }
    }
}
