@mixin messages($type) {
    font-size: 13px;
    display: inline-block;
    @if ($type == "warning") {
        background-color: #fcf8e3;
        border-color: #faf2cc;
        color: #8a6d3b;
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='#8a6d3b'%3E%3Cpath fill-rule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z' clip-rule='evenodd'/%3E%3C/svg%3E");
        }
    } @else if ($type == "error") {
        background-color: scale-color(color(alert, Error), $lightness: 90%);
        border: 1px solid scale-color(color(alert, Error), $lightness: 80%);
        color: color(alert, Error);
    } @else if ($type == "success") {
        background-color: #dff0d8;
        border-color: #d0e9c6;
        color: #3c763d;
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='#3c763d'%3E%3Cpath fill-rule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z' clip-rule='evenodd'/%3E%3C/svg%3E");
        }
    }
}

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin transition() {
    transition: all 0.3s ease;
}

@mixin shadow {
    box-shadow: 0 1px 5px 1px rgba(42, 42, 42, 0.1);
}

@mixin default-button {
    @include transition;
    display: inline-block;
    background-color: color(grey, Black);
    background-repeat: no-repeat;
    color: color(base, White);
    height: 48px;
    line-height: 48px;
    padding: 0 25px;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
    &._secondary {
        background-color: transparent;
        border: 1px solid color(grey, Black);
        color: color(grey, Black);

        &:hover {
            background-color: transparent;
            border-color: color(base, Black);
            color: color(base, Black);
        }
    }

    &._cta{
        background-color: color(primary, CtaGreen);
        color: color(base, White);
    }
    &._flowbox, &._content {
        background-color: color(primary, DarkBlue);
        border-color: color(primary, DarkBlue);
        &:hover {
            background-color: transparent;
            border-color: color(primary, DarkBlue);
            color: color(primary, DarkBlue);
        }
    }
    &._small {
        height: 34px;
        line-height: 34px;
        font-size: 12px;
        padding: 0 10px;
    }
    @include breakpoint(small only) {
        height: 38px;
        line-height: 38px;
        font-size: 14px;
    }
    &:hover {
        background-color: color(base, Black);
        text-decoration: none;
    }
    &:disabled {
        cursor: not-allowed;
        background-color: color(grey, DarkerGrey);
    }
}

@mixin loading {
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    left: -5px;
    right: -5px;
    top: 0;
    bottom: 0;
    z-index: 7;
    ._spinner {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cpath fill='%23000' d='M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
        width: 40px;
        height: 40px;
        background-size: contain;
        display: block;
        margin: 10% auto;
        mask-size: contain;
        z-index: 8;
    }
}

@mixin header-line {
    font-size: 23px;
    line-height: 28px;
    margin: 0 0 20px;
    display: flex;
    align-items: center;
    @include breakpoint(small only) {
        font-size: 18px;
    }
}

._button {
    @include default-button;
}

._loading {
    @include loading;
}
