.checkout-checkout_body {
    .grid-container {
        max-width: 45em;
    }
    .footer__container, .bottom__footer {
        display: none;
    }
    footer {
        border-top: 1px solid color(grey, ExtraSoftGrey);
        .absolute__footer-container {
            padding: 30px 0 15px;
            .grid-container > * {
                margin: 0 0 15px;
            }
        }
    }
    header {
        margin: 0 0 20px;
        .header-container {
            padding: 15px 0;
            ._logo {
                display: block;
                img {
                    width: auto;
                    height: 46px;
                }
            }
            h1 {
                color: color(base, White);
                line-height: 46px;
            }
            p {
                display: inline-block;
                padding: 0 0 0 25px;
                line-height: 46px;
                font-weight: 600;
                color: color(alert, Success);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-lock' width='20' height='20' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(alert, Success)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Crect x='5' y='11' width='14' height='10' rx='2' /%3E%3Ccircle cx='12' cy='16' r='1' /%3E%3Cpath d='M8 11v-4a4 4 0 0 1 8 0v4' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: left center;
                margin-left: auto;
            }
        }
    }
    .checkout-checkout {
        margin: 0 0 30px;
        .breadcrumbs {
            display: none;
        }
        .progress-bar {
            margin-bottom: 20px;
            @include breakpoint(small only) {
                .cell {
                    padding: 0;
                }
            }
            li {
                .progress-bar-item {
                    display: block;
                    background-color: color(grey, ExtraSoftGrey);
                    color: color(grey, Black);
                    font-weight: 500;
                    line-height: 40px;
                    padding: 0 20px;
                    font-size: 14px;
                    background-repeat: no-repeat;
                    background-position: right 20px center;
                    cursor: pointer;
                    &._active {
                        cursor: default;
                        background-color: color(grey, Black);
                        color: color(base, White);
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='16px' viewBox='0 0 10 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPage 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon stroke='%23FFFFFF' stroke-width='2' transform='translate(5.142833, 8.000000) scale(-1, 1) translate(-5.142833, -8.000000) ' points='7.99987377 2 2 7.99987377 7.99987377 14 8.2856662 13.7139551 2.57183733 7.99987377 8.2856662 2.2860449'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                    }
                    &._complete {
                        cursor: pointer;
                        background-color: color(alert, Success);
                        color: color(base, White);
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='6.13132462 8.81921546 3.04945992 5.73735077 2 6.77942013 6.13132462 10.9107447 15 2.04206936 13.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='#{color(base, White)}' stroke-width='1.5' fill='%23178038' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
                    }
                    @include breakpoint(small only) {
                        line-height: 34px;
                        font-size: 12px;
                        padding: 0 10px;
                        text-align: center;
                        &._active, &._complete {
                            background-image: none;
                        }
                    }
                }
            }
        }
        .checkout-steps-container {
            h2 {
                font-weight: 500;
                font-size: 22px;
                margin: 0 0 20px;
            }
            .shipping__address, .billing__address {
                @include breakpoint(medium) {
                    max-width: 65%;
                }
                .shipping-address-select {
                    margin: 0 0 15px;
                    label {
                        color: color(grey, Black);
                        font-size: 13px;
                        font-weight: 500;
                        margin: 0 0 5px;
                    }
                }
                &.billing__address {
                    margin: 20px 0 0;
                }
                .checkout__address-autocomplete_result {
                    margin: 0 0 15px;
                    > label {
                        font-size: 12px;
                        cursor: pointer;
                        text-decoration: underline;
                        margin: 5px 0 0;
                    }
                    .resolved__address {
                        > span {
                            display: block;
                            font-weight: 500;
                            font-size: 12px;
                        }
                        address {
                            margin: 10px 0 0;
                            display: inline-block;
                            padding: 15px 20px;
                            font-size: 14px;
                            line-height: 24px;
                            min-width: 65%;
                            background: color(base, WhiteSecond);
                            @include breakpoint(small only) {
                                min-width: 100%;
                            }
                        }
                        label {
                            font-size: 12px;
                            cursor: pointer;
                            text-decoration: underline;
                            margin: 5px 0 0;
                        }
                    }
                }
                .field__group {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 rem-calc(-($grid__gutter / 2)) 15px;
                    @include breakpoint(small only) {
                        margin-bottom: 10px;
                    }
                    .actions {
                        padding: 0 rem-calc($grid__gutter / 2);
                        margin: 10px 0 15px;
                        display: flex;
                        button {
                            height: 40px;
                            line-height: 40px;
                            font-size: 13px;
                            margin-right: 15px;
                        }
                        a {
                            line-height: 40px;
                            font-size: 13px;
                            text-decoration: underline;
                        }
                    }
                    .checkout__field {
                        padding: 0 rem-calc($grid__gutter / 2);
                        label {
                            color: color(grey, Black);
                            font-size: 13px;
                            font-weight: 500;
                            margin: 0 0 5px;
                        }
                        input {
                            &.failed {
                                border-color: color(alert, Error);
                            }
                            &.valid {
                                border-color: color(alert, Success);
                                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='6.13132462 8.81921546 3.04945992 5.73735077 2 6.77942013 6.13132462 10.9107447 15 2.04206936 13.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='%23178038' stroke-width='1.5' fill='%23178038' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
                                background-repeat: no-repeat;
                                background-position: right 10px center;
                                background-size: 12px;
                            }
                        }
                        &.email, &.password {
                            width: 70%;
                        }
                        &.company {
                            width: 50%;
                        }
                        &.street_0 {
                            width: 55%;
                        }
                        &.street_1 {
                            width: 25%;
                        }
                        &.street_2 {
                            width: 20%;
                        }
                        &.postcode {
                            width: 30%;
                        }
                        &.city {
                            width: 55%;
                        }
                        &.telephone {
                            width: 55%;
                        }
                        @include breakpoint(small only) {
                            &.firstname, &.lastname, &.street_1, &.street_2 {
                                width: 50%;
                            }
                            &.email, &.company, &.street_0, &.country, &.postcode, &.city, &.telephone {
                                width: 100%;
                            }
                            &.street_0, &.postcode {
                                margin-bottom: 10px;
                            }
                        }
                    }
                    .checkout__login__link {
                        padding: 0 rem-calc($grid__gutter / 2);
                        font-size: 13px;
                        color: color(grey, Black);
                        margin-top: 10px;
                        span {
                            cursor: pointer;
                            text-decoration: underline;
                        }
                    }
                }
                .billing-same-as-shipping-control {
                    display: flex;
                    input {
                        margin-right: 10px;
                    }
                    label {
                        cursor: pointer;
                        font-size: 14px;
                        color: color(grey, Black);
                        font-weight: 500;
                    }
                }
            }
            .shipping__methods, .payment__methods {
                position: relative;
                ._loading {
                    background: none;
                    position: static;
                    ._spinner {
                        margin: 0;
                    }
                }
                .shipping__methods-list, .payment__methods-list {
                    li:last-of-type label {
                        border-bottom: none;
                    }
                    .shipping__method, .payment__method {
                        input {
                            position: absolute;
                            left: -999999px;
                        }
                        label {
                            position: relative;
                            margin: 10px 0 0;
                            cursor: pointer;
                            padding: 10px 0 10px 40px;
                            line-height: 30px;
                            border-bottom: 1px solid color(grey, ExtraSoftGrey);
                            @include breakpoint(medium) {
                                width: 75%;
                            }
                            &:before {
                                content: '';
                                border: 1px solid color(grey, Black);
                                display: block;
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 20px;
                                height: 20px;
                                border-radius: 2px;
                                margin-right: 8px;
                            }
                            &:hover:before {
                                border-color: color(grey, MediumGrey);
                            }
                            ._title {
                                font-weight: 500;
                                display: block;
                                line-height: 24px;
                                span {
                                    margin-left: 5px;
                                }
                                ._free {
                                    color: color(alert, Success);
                                }
                            }
                            ._sub {
                                display: block;
                                font-size: 13px;
                                line-height: 18px;
                            }
                            img {
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                right: 10px;
                                max-height: 35px;
                            }
                        }
                        input:checked + label:after {
                            content: '';
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='7px' viewBox='0 0 10 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EFill 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpolygon id='Fill-1' stroke='%23231F20' fill='%23FFFFFF' points='3.54581617 5.53783002 1.6370223 3.34424853 1 4.0737009 3.54581617 7 9 0.732064558 8.36525075 0'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            background-position: center;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 0;
                            width: 20px;
                            height: 20px;
                        }
                        .payment__method__options {
                            @include breakpoint(medium) {
                                max-width: 50%;
                            }
                            padding: 10px;
                            margin: 10px 0 0;
                        }
                    }
                }
            }
            .coupon-code__container {
                position: relative;
                margin: 30px 0 0;
                .toggle-coupon {
                    display: flex;
                    input {
                        margin-right: 7px;
                    }
                    label {
                        color: color(grey, Black);
                        cursor: pointer;
                        font-size: 14px;
                    }
                }
                ._loading {
                    position: static;
                    ._spinner {
                        margin: 10px 0 0;
                    }
                }
                .coupon-code__form {
                    margin: 15px 0 0;
                    width: 55%;
                    .form-holder {
                        display: flex;
                        input {
                            margin-right: 5px;
                        }
                        button {
                            height: 44px;
                            line-height: 44px;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
        .checkout-summary {
            margin: 40px 0 0;
            color: color(grey, Black);
            h4 {
                font-weight: 500;
            }
            .summary-block {
                position: relative;
                background: color(grey, ExtraSoftGrey);
                margin: 10px 0 0;
                padding: 20px;
                @include breakpoint(small only) {
                    margin: 10px 0 20px;
                }
                ._loading {
                    position: static;
                    background: none;
                }
                h5 {
                    font-weight: 500;
                    font-size: 14px;
                    margin: 0 0 7px;
                }
                .cart__items, .cart__totals, .delivery__address {
                    &.cart__items {
                        margin: 0 0 15px;
                    }
                    li {
                        line-height: 23px;
                        font-size: 13px;
                        display: flex;
                        flex-wrap: wrap;
                        ._free {
                            color: color(alert, Success);
                        }
                        span:first-child {
                            width: 80%;
                        }
                        span:last-child {
                            width: 20%;
                        }
                        &._grand {
                            font-weight: 600;
                            font-size: 15px;
                        }
                    }
                }
                button {
                    font-size: 13px;
                    cursor: pointer;
                    text-decoration: underline;
                    margin: 10px 0 0;
                }
            }
        }
        .checkout-footer {
            margin: 30px 0 0;
            display: flex;
            flex-wrap: wrap;
            background: color(grey, ExtraSoftGrey);
            padding: 20px;
            button._cart-loading {
                background-size: 20px;
                background-position: right 15px center;
            }
            a {
                margin-right: 20px;
                font-size: 13px;
                line-height: 48px;
            }
            @include breakpoint(medium) {
                justify-content: flex-end;
            }
            @include breakpoint(small only) {
                margin: 20px 0 0;
                a {
                    line-height: normal;
                    margin: 5px 0 20px;
                }
                button {
                    width: 100%;
                    padding: 0 20px;
                    height: 48px;
                    line-height: 48px;
                }
            }
        }
    }
}
