.search__autocomplete {
    ._loading {
        width: 42px;
        height: 42px;
        right: 1px;
        left: auto;
        top: 1px;
        bottom: 1px;
        background: color(base, White);
        @include breakpoint(small only) {
            width: 30px;
            height: 30px;
        }
        ._spinner {
            margin: 8px auto;
            width: 24px;
            height: 24px;
            @include breakpoint(small only) {
                margin: 3px;
            }
        }
    }
    ._remove {
        @include transition;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 45px;
        margin: 12px 0;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EX%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='X' transform='translate(1.000000, 1.000000)' stroke='#{color(grey, SoftGrey)}' stroke-width='2'%3E%3Cline x1='5.35924893e-14' y1='-1.33981223e-14' x2='14' y2='14' id='Line'%3E%3C/line%3E%3Cpath d='M0,14 L14,0 L0,14 Z' id='Line'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        &:hover {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EX%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='X' transform='translate(1.000000, 1.000000)' stroke='#{color(grey, MediumGrey)}' stroke-width='2'%3E%3Cline x1='5.35924893e-14' y1='-1.33981223e-14' x2='14' y2='14' id='Line'%3E%3C/line%3E%3Cpath d='M0,14 L14,0 L0,14 Z' id='Line'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
        @include breakpoint(small only) {
            margin: 6px 0;
            right: 38px;
        }
    }
    .search__autocomplete_dropdown {
        position: absolute;
        background: color(base, White);
        border: 1px solid color(grey, ExtraSoftGrey);
        left: -10%;
        right: -10%;
        top: 50px;
        @include breakpoint(small only) {
            left: 0;
            right: 0;
            top: 48px;
        }
        ul {
            li {
                a {
                    display: flex;
                    padding: 5px 10px;
                    border-bottom: 1px solid color(grey, ExtraSoftGrey);
                    justify-content: center;
                    &:hover {
                        text-decoration: none;
                        background: color(grey, ExtraSoftGrey);
                    }
                    .image__holder {
                        display: block;
                        margin-right: 10px;
                        img {
                            margin: auto;
                            aspect-ratio: 1/1;
                            width: 50px;
                            height: 50px;
                            object-fit: scale-down;
                        }
                    }
                    ._title {
                        flex: 1;
                        padding: 9px 0;
                        font-size: 14px;
                        line-height: 20px;
                        color: color(base, Black);
                        font-weight: normal;
                        @include breakpoint(small only) {
                            font-size: 12px;
                            line-height: 16px;
                            margin-right: 10px;
                        }
                    }
                    .price__holder {
                        display: block;
                        margin: 10px 0 0;
                        color: #323337;
                        .price__old-price {
                            font-size: 14px;
                            line-height: 20px;
                            text-decoration: line-through;
                            display: inline-block;
                            margin-right: 5px;
                            @include breakpoint(small only) {
                                font-size: 14px;
                            }
                        }
                        .price {
                            display: inline-block;
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 600;
                            @include breakpoint(small only) {
                                font-size: 14px;
                            }
                            &._special {
                                font-weight: 600;
                                color: color(alert, Warning);
                            }
                        }
                    }
                }
                &.more__results a {
                    border: none;
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 20px;
                }
            }
        }
        .no__results {
            display: block;
            text-align: center;
            font-size: 12px;
            font-weight: normal;
            line-height: 30px;
        }
    }
}
