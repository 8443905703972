.breadcrumbs {
    font-size: 14px;
    line-height: 30px;
    margin: 10px 0 0;
    ol {
        display: flex;
        padding-bottom: 15px;
        li {
            display: flex;
            a, span {
                color: color(grey, DarkerGrey);
                text-transform: lowercase;
                display: block;
            }

            ._sep {
                margin: 0 10px;
            }
        }
    }

    .breadcrumb-router {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='6px' height='11px' viewBox='0 0 6 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EFill 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M8.04072727,3.23709091 L3.23709091,8.04145455 C3.17527273,8.10327273 3.08218182,8.14472727 3,8.14472727 C2.91709091,8.14472727 2.82472727,8.10327273 2.76290909,8.04145455 L-2.04145455,3.23709091 C-2.10327273,3.17527273 -2.14472727,3.08290909 -2.14472727,3 C-2.14472727,2.91781818 -2.10327273,2.82472727 -2.04145455,2.76290909 L-1.52581818,2.248 C-1.464,2.18618182 -1.38181818,2.14472727 -1.28872727,2.14472727 C-1.20654545,2.14472727 -1.11345455,2.18618182 -1.05163636,2.248 L3,6.29890909 L7.05163636,2.248 C7.11272727,2.18618182 7.20581818,2.14472727 7.28872727,2.14472727 C7.37090909,2.14472727 7.464,2.18618182 7.52581818,2.248 L8.04072727,2.76290909 C8.10254545,2.82472727 8.14472727,2.91781818 8.14472727,3 C8.14472727,3.08290909 8.10254545,3.17527273 8.04072727,3.23709091' id='Fill-1' fill='%23303030' transform='translate(3.000000, 5.144727) rotate(90.000000) translate(-3.000000, -5.144727) '%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 15px;

    }
}
