@use "sass:math";

.page-builder__wrapper {
    .page-builder__content-block {
        &.--first {
            margin: 0 0 20px 0;
            @include breakpoint(medium) {
                margin: 0 0 40px 0;
            }
        }
        &:not(.--first) {
            margin: 20px 0;
            @include breakpoint(medium) {
                margin: 40px 0;
            }
        }
        .flow-box__container {
            background: color(grey, Flowbox);
            padding: 40px 0 30px;
            h2 {
                text-align: center;
                font-weight: bold;
                margin: 0 0 5px;
                @include breakpoint(small only) {
                    font-size: 20px;
                }
            }
            a {
                margin: 20px auto 10px;
                display: block;
            }
            h2 + p {
                text-align: center;
                display: inherit;
                padding: 0 0 20px;
            }
            a {
                display: block;
                padding: 0 rem-calc($grid__gutter / 2);
                @include breakpoint(medium) {
                    width: 20%;
                }
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .catalog-category-page {
            .toolbar__top {
            }
        }
    }
}

@include breakpoint(small only) {
    .--no-padding-mobile {
        padding: 0;
    }
}
