.catalog__category-list {
    position: relative;
    > ul {
        li {
            > a {
                @include transition;
                position: relative;
                display: block;
                padding: 10px;
                border: 1px solid transparent;
                margin-bottom: rem-calc($grid__gutter);
                @include breakpoint(small only) {
                    padding: 10px;
                }
                &:hover {
                    text-decoration: none;
                    border-color: color(grey, SoftGrey);
                }
                .remove__wishlist {
                    display: block;
                    width: 30px;
                    height: 30px;
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EX%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='X' transform='translate(1.000000, 1.000000)' stroke='#{color(grey, SoftGrey)}' stroke-width='2'%3E%3Cline x1='5.35924893e-14' y1='-1.33981223e-14' x2='14' y2='14' id='Line'%3E%3C/line%3E%3Cpath d='M0,14 L14,0 L0,14 Z' id='Line'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: center;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    &:hover {
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EX%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='X' transform='translate(1.000000, 1.000000)' stroke='#{color(grey, MediumGrey)}' stroke-width='2'%3E%3Cline x1='5.35924893e-14' y1='-1.33981223e-14' x2='14' y2='14' id='Line'%3E%3C/line%3E%3Cpath d='M0,14 L14,0 L0,14 Z' id='Line'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    }
                }
                .image__holder {
                    display: block;
                    img {
                        margin: auto;
                        aspect-ratio: 1/1;
                        object-fit: scale-down;
                    }
                }
                span.label h4 {
                    background: #2E4465;
                    display: inline-block;
                    margin: 10px 15px 0px 0px;
                    padding: 5px 10px;
                    position: relative;
                    color: white;
                    font-weight: bold;
                    @media screen and (max-width: 720px) {
                        padding: 2px 6px;
                        font-weight: 500;
                        font-size: 12px;
                    }
                }
                span.labeltype_donkerblauw h4:after {
                    border: 13px solid transparent;
                    border-left-color: #2E4465;
                    content: " ";
                    display: block;
                    position: absolute;
                    right: -26px;
                    top: 0px;
                    @media screen and (max-width: 720px) {
                        border: 8px solid transparent;
                        border-left-color: #2e4465;
                        right: -15px;
                    }
                }
                span.labeltype_perzik h4 {
                    border: #CEA591 2px solid;
                    background-color: white;
                    color: #CEA591;
                }
                span.labeltype_lichtblauw h4 {
                    background-color: #7fa7c1;
                }
                .product__holder {
                    padding: 10px 0;
                    display: block;
                    .product__name {
                        h2 {
                            font-size: 16px;
                            line-height: 20px;
                            @include breakpoint(small only) {
                                font-size: 14px;
                                line-height: 30px;
                            }
                        }
                    }
                    ._brand {
                        display: block;
                        color: color(grey, Black);
                        font-size: 12px;
                        font-weight: 400;
                        padding-top: 10px;
                    }
                    .price__holder {
                        display: block;
                        margin: 10px 0 0;
                        color: #323337;
                        .price__old-price {
                            font-size: 14px;
                            line-height: 20px;
                            text-decoration: line-through;
                            display: inline-block;
                            margin-right: 5px;
                            @include breakpoint(small only) {
                                font-size: 14px;
                            }
                        }
                        .price {
                            display: inline-block;
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 600;
                            @include breakpoint(small only) {
                                font-size: 14px;
                            }
                            &._special {
                                font-weight: 600;
                                color: color(alert, Warning);
                            }
                        }
                    }
                    ._stock {
                        margin: 3px 0 0 0;
                        display: inline-block;
                        color: color(primary, CtaGreen);
                        font-size: 14px;
                        line-height: 23px;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23a6cc4d' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E%0A");                        background-repeat: no-repeat;
                        padding-left: 20px;
                        background-position: left;
                        background-size: 18px;
                        font-weight: 500;
                        display: block;
                    }
                    ._options {
                        display: block;
                        color: color(grey, DarkerGrey);
                        font-size: 13px;
                        padding-top: 5px;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .category__hotspot {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.recent-products {
    ul {
        margin: 0 rem-calc(-($grid__gutter / 2));
        .swiper-slide {
            width: 16.6666666667%;
            @include breakpoint(small only) {
                width: 50%;
            }
            li {
                margin: 0 rem-calc($grid__gutter / 2);
            }
        }
    }
}

.featured-item {
    background: color(base, LightBlue);
    color: color(base, White);
    box-sizing: border-box;
    padding: 20px;
    margin: 0 0 20px;
    h2 {
        font-size: 26px;
        line-height: 34px;
        margin: 0 0 15px;
        font-weight: 500;
        color: color(base, White);
        @include breakpoint(large down) {
            font-size: 23px;
            line-height: 36px;
        }
        @include breakpoint(small only) {
            font-size: 23px;
            line-height: 36px;
        }
    }
    p {
        font-size: 15px;
        line-height: 24px;
        margin: 0 0 10px;
        @include breakpoint(large down) {
            font-size: 14px;
            line-height: 21px;
        }
        @include breakpoint(small only) {
            font-size: 14px;
            line-height: 21px;
        }
    }
    a {
        font-size: 15px;
        line-height: 24px;
        color: color(base, White);
        text-decoration: underline;
    }
}
