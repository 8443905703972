.checkout-cart_body {
    .header__usp, .footer__usps, .footer__brands {
        display: none;
    }
    .checkout-cart {
        margin: 10px 0 40px;
        .breadcrumbs {
            display: none;
        }
        .cart-header {
            margin: 20px 0;
            h1 {
                font-size: 23px;
                line-height: 44px;
                display: flex;
                align-items: center;
            }
            .header-container {
                display: flex;
                flex-wrap: wrap;
                @include breakpoint(medium) {
                    justify-content: flex-end;
                }
                p {
                    padding: 0 25px;
                    line-height: 44px;
                    font-weight: 600;
                    color: color(alert, Success);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-lock' width='20' height='20' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(alert, Success)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Crect x='5' y='11' width='14' height='10' rx='2' /%3E%3Ccircle cx='12' cy='16' r='1' /%3E%3Cpath d='M8 11v-4a4 4 0 0 1 8 0v4' /%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: left center;
                    @include breakpoint(small only) {
                        font-size: 12px;
                    }
                }

                button {
                    &[disabled]._cart-loading {
                        background-position: right 10px center;
                    }
                    @include breakpoint(medium) {
                        height: 44px;
                        line-height: 44px;
                    }
                    @include breakpoint(small only) {
                        margin-left: auto;
                    }
                }
            }
        }

        .cart__message {
            @include messages('error');
            width: 100%;
            margin: 0 0 20px;
            border-radius: 4px;
            line-height: 30px;
            padding: 0 15px;
        }

        .cart-holder {
            .cart-container {
                .cart__item {
                    border-bottom: 1px solid color(grey, ExtraSoftGrey);
                    .cart__image {
                        display: block;
                        @include breakpoint(small only) {
                            padding: 10px 0;
                            width: auto;
                        }
                        img {
                            margin: auto;
                            aspect-ratio: 1/1;
                            width: 100%;
                            height: 100%;
                            object-fit: scale-down;
                        }
                    }
                    .cart__item__information {
                        padding: 20px 0;
                        a {
                            font-weight: 500;
                            line-height: 24px;
                        }
                        ul {
                            li {
                                > span {
                                    font-weight: 500;
                                }
                                font-size: 13px;
                                line-height: 19px;
                            }
                        }
                    }

                    .cart__item__qty {
                        padding: 20px 0;
                        display: flex;
                        @include breakpoint(small only) {
                            padding: 10px 0;
                        }
                        @include breakpoint(940px) {
                            padding: 20px;
                        }
                        select {
                            width: 70px;
                            line-height: 40px;
                            height: 40px;
                            padding: 0 15px;
                            background-position: right -5px center;
                        }

                        button {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-trash' width='20' height='20' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(grey, MediumGrey)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cline x1='4' y1='7' x2='20' y2='7' /%3E%3Cline x1='10' y1='11' x2='10' y2='17' /%3E%3Cline x1='14' y1='11' x2='14' y2='17' /%3E%3Cpath d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' /%3E%3Cpath d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' /%3E%3C/svg%3E");
                            width: 20px;
                            height: 20px;
                            margin: 10px 0 10px 10px;
                            cursor: pointer;
                            @include breakpoint(940px) {
                                margin: 10px 0 10px 20px;
                            }

                            &:hover {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-trash' width='20' height='20' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(grey, DarkerGrey)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cline x1='4' y1='7' x2='20' y2='7' /%3E%3Cline x1='10' y1='11' x2='10' y2='17' /%3E%3Cline x1='14' y1='11' x2='14' y2='17' /%3E%3Cpath d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' /%3E%3Cpath d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' /%3E%3C/svg%3E");
                            }
                        }
                    }

                    .row__price {
                        position: relative;
                        padding: 20px;
                        display: flex;
                        font-weight: 500;
                        @include breakpoint(small only) {
                            padding: 10px 0;
                        }

                        > span {
                            margin-left: auto;
                            line-height: 40px;
                        }

                        ._loading {
                            @include loading;
                            position: relative;

                            ._spinner {
                                width: 24px;
                                height: 24px;
                                margin: 8px 0;
                            }
                        }
                    }
                }
            }

            .totals-container {
                background: color(base, WhiteSecond);
                color: color(grey, Black);
                padding: 20px;
                margin: 20px 0 0;

                .totals-container__totals {
                    position: relative;
                    ._loading {
                        position: static;
                        ._spinner {
                            margin: 10px auto;
                        }
                    }
                    ul {
                        margin-bottom: 1rem;
                        li {
                            display: flex;
                            align-items: center;
                            span {
                                line-height: 30px;
                                font-weight: 500;
                                &._shipping {
                                    font-size: 13px;
                                    max-width: 80%;
                                    text-align: right;
                                    line-height: 19px;
                                }
                                &._free {
                                    color: color(alert, Success);
                                }
                                &:last-child {
                                    margin-left: auto;
                                }
                            }
                            &._grand span {
                                font-size: 18px;
                                font-weight: 700;
                            }
                        }
                    }
                }

                ._actions {
                    padding: 20px 0 0;
                    display: flex;
                    flex-wrap: wrap;
                    @include breakpoint(medium) {
                        justify-content: flex-end;
                    }
                    a {
                        line-height: 48px;
                        font-size: 14px;
                        margin-right: 30px;
                        padding-left: 15px;
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='7px' height='12px' viewBox='0 0 7 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EFill 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M8.18545455,4.09236364 L3.38181818,8.89672727 C3.32,8.95854545 3.22690909,9 3.14472727,9 C3.06181818,9 2.96945455,8.95854545 2.90763636,8.89672727 L-1.89672727,4.09236364 C-1.95854545,4.03054545 -2,3.93818182 -2,3.85527273 C-2,3.77309091 -1.95854545,3.68 -1.89672727,3.61818182 L-1.38109091,3.10327273 C-1.31927273,3.04145455 -1.23709091,3 -1.144,3 C-1.06181818,3 -0.968727273,3.04145455 -0.906909091,3.10327273 L3.14472727,7.15418182 L7.19636364,3.10327273 C7.25745455,3.04145455 7.35054545,3 7.43345455,3 C7.51563636,3 7.60872727,3.04145455 7.67054545,3.10327273 L8.18545455,3.61818182 C8.24727273,3.68 8.28945455,3.77309091 8.28945455,3.85527273 C8.28945455,3.93818182 8.24727273,4.03054545 8.18545455,4.09236364' id='Fill-1' fill='#{color(grey, Black)}' transform='translate(3.144727, 6.000000) scale(1, -1) rotate(-270.000000) translate(-3.144727, -6.000000) '%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-position: left center;
                        @include breakpoint(small only) {
                            line-height: 24px;
                            margin: 0 0 15px;
                        }
                    }
                    button {
                        padding: 0 50px;
                        @include breakpoint(small only) {
                            width: 100%;
                            height: 48px;
                            line-height: 48px;
                        }
                    }
                }
                p {
                    font-size: 12px;
                    line-height: 20px;
                    text-align: right;
                    margin: 10px 0 0 auto;
                    @include breakpoint(medium) {
                        max-width: 75%;
                    }

                    a {
                        font-weight: 500;
                    }
                }
            }
        }

        .product__crosssell {
            position: relative;
            margin: 20px 0 40px;
            h3 {
                font-size: 22px;
                line-height: 41px;
                margin: 0 0 20px;
                text-align: left;
                font-weight: normal;
                @include breakpoint(small only) {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
            .catalog__category-list {
                position: relative;
                .swiper-slide {
                    width: 20%;
                    li a {
                        margin: 0 5px;
                    }
                }
                ._loading {
                    position: static;
                    ._spinner {
                        margin: 10px auto;
                    }
                }
            }
        }
        .cart-empty {
            min-height: 235px;
        }
    }
}
