.catalog__category {
    .off-canvas.position-left {
        width: 100%;
    }
    .catalog__category-top {
        display: flex;
        align-items: center;
        margin: 0 0 20px 0;
        flex-wrap: wrap;
        ._title {
            display: flex;
            flex: 30%;
            @include breakpoint(small only) {
                flex: 100%;
            }
            h1 {
                width: 100%;
                line-height: 28px;
                font-size: 23px;
                display: flex;
                align-items: center;
                @include breakpoint(small only) {
                    line-height: 23px;
                    font-size: 14px;
                }
            }
        }
    }

    .toolbar__top {
        display: flex;
        @include breakpoint(small only) {
            flex: 100%;
            flex-wrap: wrap;
        }
        .toolbar__filter-container {
            margin-left: auto;
            display: flex;
            @include breakpoint(small only) {
                flex: 100%;
            }
        }
        .mobile__filter-page-container {
            display: flex;
            width: 100%;
            margin: 15px 0 10px;
            span {
                width: 50%;
                font-size: 14px;
                line-height: 17px;
                &:nth-of-type(2) {
                    text-align: right;
                }
            }
        }
        .mobile__filter-container {
            @include breakpoint(small only) {
                padding: 0 15px 0 0;
                width: 100%;
                display: block;
            }
            span {
                border-radius: 2px;
                border: 1px solid color(grey, SoftGrey);
                width: 100%;
                text-align: left;
                padding: 0 15px;
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='8px' viewBox='0 0 14 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow down%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='%238D97A4' fill-rule='nonzero' transform='translate(6.786632, 4.000000) rotate(-90.000000) translate(-6.786632, -4.000000) ' points='10.7866324 -1.57326478 9.57326478 -2.78663239 2.78663239 4 9.57326478 10.7866324 10.7866324 9.57326478 5.21336761 4'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                background-position: right 10px center;
                background-repeat: no-repeat;
                line-height: 38px;
                height: 38px;
            }
        }
        .catalog__category-sort {
            display: flex;
            flex-wrap: wrap;
            @include breakpoint(small only) {
                padding: 0 0 0 15px;
                width: 100%;
            }
        }
        .catalog__category-sorter {
            display: flex;
            flex-wrap: wrap;
            @include breakpoint(small only) {
                width: 100%;
            }
            .off-canvas__filter_toggle {
                width: 50%;
            }
            span {
                flex-shrink: 1;
                line-height: 42px;
                font-size: 14px;
                color: color(grey, DarkerGrey);
            }
            select {
                flex: 1;
                outline: none;
                border: none;
                color: color(base, Black);
                padding-left: 5px;
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='8px' viewBox='0 0 14 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow down%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='%23303030' fill-rule='nonzero' transform='translate(6.786632, 4.000000) rotate(-90.000000) translate(-6.786632, -4.000000) ' points='10.7866324 -1.57326478 9.57326478 -2.78663239 2.78663239 4 9.57326478 10.7866324 10.7866324 9.57326478 5.21336761 4'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                @include breakpoint(small only) {
                    border: 1px solid color(grey, SoftGrey);
                    border-radius: 2px;
                    height: 38px;
                    line-height: 38px;
                    padding-left: 15px;
                }
            }
        }
        .per-page {
            display: flex;
            flex-wrap: wrap;
            margin-right: 10px;
            span {
                flex-shrink: 1;
                line-height: 42px;
                font-size: 14px;
                color: color(grey, DarkerGrey);
            }
            select {
                flex: 1;
                outline: none;
                border: none;
                color: color(base, Black);
                padding-left: 5px;
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='8px' viewBox='0 0 14 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow down%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='%23303030' fill-rule='nonzero' transform='translate(6.786632, 4.000000) rotate(-90.000000) translate(-6.786632, -4.000000) ' points='10.7866324 -1.57326478 9.57326478 -2.78663239 2.78663239 4 9.57326478 10.7866324 10.7866324 9.57326478 5.21336761 4'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    .toolbar__bottom {
        display: flex;
        flex-wrap: wrap;
        .product__per__page {
            margin: 0 0 20px;
            text-align: center;
            color: color(grey, DarkerGrey);
        }
        .back-to-top {
            margin: 0 0 10px auto;
            cursor: pointer;
            display: block;
            width: 40px;
            height: 40px;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='40px' height='40px' viewBox='0 0 40 40' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Casba' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='02-Casba_Categorie_Desktop_V2' transform='translate(-1571.000000, -3083.000000)'%3E%3Cg id='Paging' transform='translate(591.000000, 3083.000000)'%3E%3Cg id='to-top' transform='translate(980.000000, 0.000000)'%3E%3Cpolygon id='Arrow-down' stroke='%23FFFFFF' fill='%23303030' fill-rule='nonzero' transform='translate(20.500000, 23.000000) scale(-1, 1) rotate(90.000000) translate(-20.500000, -23.000000) ' points='26 15.6090909 24.3316195 14 15 23 24.3316195 32 26 30.3909091 18.3367609 23'%3E%3C/polygon%3E%3Crect id='Rectangle' stroke='%23303030' x='0.5' y='0.5' width='39' height='39'%3E%3C/rect%3E%3Cline x1='11.5' y1='11.5' x2='29.5' y2='11.5' id='Line-4' stroke='%23303030' stroke-linecap='square'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
    }
    .flowbox__tags {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin: 30px 0 10px;
        button {
            @include transition;
            display: block;
            cursor: pointer;
            text-transform: capitalize;
            background: color(base, LightBlue);
            color: color(base, White);
            line-height: 40px;
            font-size: 14px;
            padding: 0 15px;
            @include breakpoint(small only) {
                font-size: 12px;
                line-height: 32px;
            }
            &:hover {
                background: color(base, LightBlueHover);
            }
            &._active {
                pointer-events: none;
                background: color(grey, DarkerGrey);
            }
        }
    }
}

#js-flowbox-flow {
    margin-top: 80px;
}

.category__description {
    margin: 30px 0 70px;
    padding: 50px 0 0;
    h2 {
        font-size: 16px;
        line-height: 26px;
        font-weight: 600;
        margin: 0 0 10px;
    }
    p {
        line-height: 26px;
        margin: 0 0 25px;
    }
}

.catalog__category-lastviewed {
    .image__holder {
        img {
            width: 100%;
            padding: 30px;
            box-sizing: border-box;
        }
    }
}

.home_product-item {
    img {
        width: 100%;
        padding: 30px;
        box-sizing: border-box;
        @include breakpoint(small only) {
            padding: 0;
        }
    }

}

.catalog__category-lastviewed {
    margin: 40px 0 0;
}
