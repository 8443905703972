input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input[type='text'], input[type='search'], input[type='tel'], input[type='email'], input[type='number'], input[type='password'] {
    border: 1px solid color(grey, SoftGrey);
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    padding: 0 20px;
    color: color(grey, DarkerGrey);
    @include breakpoint(small only) {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        padding: 0 10px;
    }
}

select {
    border: 1px solid color(grey, SoftGrey);
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    padding: 0 30px 0 15px;
    color: color(grey, DarkerGrey);
    background-image: url("data:image/svg+xml,%0A%3Csvg width='14px' height='8px' viewBox='0 0 14 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='%23303030' fill-rule='nonzero' transform='translate(6.786632, 4.000000) rotate(-90.000000) translate(-6.786632, -4.000000) ' points='10.7866324 -1.57326478 9.57326478 -2.78663239 2.78663239 4 9.57326478 10.7866324 10.7866324 9.57326478 5.21336761 4'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
    background-position: right -20px center;
    background-size: unset;
    @include breakpoint(small only) {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
    }
}

textarea {
    background: color(base, White);
    font-size: 14px;
    color: color(grey, DarkerGrey);
    border: 1px solid color(grey, SoftGrey);
    padding: 8px 20px;
    line-height: 24px;
    @include breakpoint(small only) {
        font-size: 12px;
    }
}

span.field-error {
    color: color(alert, Error);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    display: table;
    margin: 3px 0 0;
}

span.field-success {
    color: color(alert, Success);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    display: table;
    margin: 3px 0 0;
}

.field__container {
    margin: 0 0 15px;
    label {
        color: color(grey, Black);
        font-size: 13px;
        font-weight: 500;
        margin: 0 0 5px;
    }
    .input__group {
        display: flex;
        padding: 10px 0 0;
        label {
            margin-right: 10px;
            font-size: 14px;
            cursor: pointer;
            display: flex;
        }
        input {
            width: auto;
            margin-right: 5px;
        }
    }
}
