footer {
    margin: 60px 0 0;
    .footer__container-newsletter {
        background: color(primary, PrimaryBlue);
        padding: 60px 0;
        @include breakpoint(small only) {
            padding: 30px 0;
        }
        h4 {
            font-size: 30px;
            line-height: 40px;
            color: color(base, White);
            @include breakpoint(small only) {
                font-size: 20px;
                line-height: 24px;
            }
        }
        p {
            color: color(base, White);
            font-size: 16px;
            font-weight: normal;
            margin: 10px 0;
            @include breakpoint(small only) {
                font-size: 14px;
            }
        }
        .newsletter__container {
            @include breakpoint(small only) {
                margin: 0 0 20px;
            }

            .newsletter__container-form {
                display: flex;
                flex-wrap: wrap;
                margin: 20px 0 0;
                @include breakpoint(small only) {
                    row-gap: 15px;
                }

                input[type='email'] {
                    flex: 1;
                    height: 48px;
                    line-height: 48px;
                    border: 0;
                    background-color: white;
                    padding: 0 20px;
                    margin-right: 20px;
                    color: color(base, Black);
                    @include breakpoint(small only) {
                        flex-basis: 100%;
                        margin-right: 0;
                    }

                    &::placeholder {
                        color: color(grey, Black);
                        opacity: 1;
                    }

                    &:-ms-input-placeholder {
                        color: color(grey, Black);
                    }

                    &::-ms-input-placeholder {
                        color: color(grey, Black);
                    }
                }

                input[type='submit'] {
                    @include default-button;
                    border: 1px solid transparent;
                    @include breakpoint(small only) {
                        flex-basis: 100%;
                    }
                }
            }
        }
    }
    .footer__container {
        display: flex;
        background: color(primary, OffWhite);
        padding: 60px 0;
        @include breakpoint(small only) {
            padding: 80px 0 60px;
        }
        .footer_menu {
            padding: 0 0 40px;
            margin: 0 0 40px;
            row-gap: 1rem;
            @include breakpoint(small only) {
                padding: 0;
                margin: 0;
                border-bottom: 1px solid transparent;
            }
        }
        >.grid-container {
            width: 100%;
            >.grid-x.grid-padding-x {
                margin: 0;
            }
        }

        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
            .root-element {
                flex-basis: 25%;
                @include breakpoint(small only) {
                    flex-basis: 100%;
                }
                @include breakpoint(medium) {
                    flex-basis: 25%;
                }
                >.group-header {
                    color: color(grey, Black);
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 26px;
                    margin: 0 0 10px;
                    @include breakpoint(small only) {
                        margin: 10px 0 20px;
                    }
                    &.socials {
                        +.navigation-group-container {
                            ul {
                                display: flex;
                                flex-wrap: wrap;
                                flex-direction: row;
                                justify-content: flex-start;
                            }
                        }
                    }
                }
                > .navigation-group-container {
                    display: flex;
                    z-index: 751;
                    .grid-container {
                        padding: 0;
                        width: 100%;
                    }
                    .navigation-group-items {
                        color: color(grey, Black);
                        border-radius: 0 0 0 5px;
                        display: flex;
                        flex-direction: column;
                        .navigation-item {
                            a, p {
                                color: color(grey, Black);
                                line-height: 32px;
                                font-size: 14px;
                                &.phone {
                                    background-image: url("data:image/svg+xml,%0A%3Csvg width='22px' height='22px' viewBox='0 0 22 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='8666632_phone_icon' transform='translate(1.111876, 0.999902)' stroke='%23303030' stroke-width='2'%3E%3Cpath d='M19.8881238,14.9200983 L19.8881238,17.9200983 C19.8904096,18.4832434 19.6551718,19.0212567 19.2402017,19.4019632 C18.8252316,19.7826697 18.2689877,19.9707864 17.7081238,19.9200983 C14.6309577,19.5857391 11.675123,18.5342429 9.0781238,16.8500983 C6.66195192,15.3147622 4.61345994,13.2662702 3.0781238,10.8500983 C1.38810186,8.2413023 0.3363668,5.27109288 0.00812379771,2.18009832 C-0.042402992,1.62097065 0.144395806,1.06635074 0.522889577,0.651719375 C0.901383349,0.237088007 1.43671804,0.000627009182 1.9981238,0 L4.9981238,0 C6.00198785,-0.00978183821 6.85762464,0.726065801 6.9981238,1.72009832 C7.12474681,2.68016477 7.35957325,3.6228252 7.6981238,4.53009832 C7.9728588,5.26097335 7.79713887,6.08490458 7.2481238,6.64009832 L5.9781238,7.91009832 C7.40167927,10.4136441 9.47457797,12.4865428 11.9781238,13.9100983 L13.2481238,12.6400983 C13.8033175,12.0910832 14.6272488,11.9153633 15.3581238,12.1900983 C16.2653969,12.5286489 17.2080573,12.7634753 18.1681238,12.8900983 C19.1736865,13.0319586 19.9133138,13.9048909 19.8881238,14.9200983 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                                    background-size: 18px;
                                    background-repeat: no-repeat;
                                    background-position: left center;
                                    padding-left: 30px;
                                    display: flex;
                                }
                                &.mail {
                                    background-image: url("data:image/svg+xml,%0A%3Csvg width='26px' height='18px' viewBox='0 0 26 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='5340267_e_email_letter_mail_icon' fill='%23303030' fill-rule='nonzero'%3E%3Cpath d='M25,6 C24.4477153,6 24,6.44771525 24,7 L24,15 C24,15.5522847 23.5522847,16 23,16 L3,16 C2.44771525,16 2,15.5522847 2,15 L2,7 C2,6.44771525 1.55228475,6 1,6 C0.44771525,6 0,6.44771525 0,7 L0,15 C0.00070020816,15.7953814 0.31723005,16.5579306 0.88,17.12 C1.44206943,17.6827699 2.2046186,17.9992998 3,18 L23,18 C23.7953814,17.9992998 24.5579306,17.6827699 25.12,17.12 C25.6827699,16.5579306 25.9992998,15.7953814 26,15 L26,7 C26,6.44771525 25.5522847,6 25,6 Z' id='Path'%3E%3C/path%3E%3Cpath d='M12.4,11.8 C12.7555556,12.0666667 13.2444444,12.0666667 13.6,11.8 L25.41,2.94 C25.7898536,2.65356094 25.9153164,2.13916343 25.71,1.71 C25.5610726,1.40232145 25.3616451,1.1217708 25.12,0.88 C24.5579306,0.31723005 23.7953814,0.00070020816 23,0 L3,0 C2.2046186,0.00070020816 1.44206943,0.31723005 0.88,0.88 C0.638354947,1.1217708 0.438927371,1.40232145 0.29,1.71 C0.0846836433,2.13916343 0.21014645,2.65356094 0.59,2.94 L12.4,11.8 Z M3,2 L23,2 C23.0927618,1.98539257 23.1872382,1.98539257 23.28,2 L13,9.75 L2.72,2 C2.81276179,1.98539257 2.90723821,1.98539257 3,2 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                                    background-size: 21px;
                                    background-repeat: no-repeat;
                                    background-position: left center;
                                    padding-left: 30px;
                                    display: flex;
                                }
                                &.address {
                                    background-image: url("data:image/svg+xml,%0A%3Csvg width='24px' height='32px' viewBox='0 0 24 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='4243256_ux_basic_app_location_icon' transform='translate(1.000000, 1.000000)' stroke='%23303030' stroke-width='2'%3E%3Cpath d='M22,11 C22,4.92486775 17.0751322,0 11,0 C4.92486775,0 0,4.92486775 0,11 C0,21 11,30 11,30 C11,30 22,21 22,11 Z' id='Path'%3E%3C/path%3E%3Ccircle id='Oval' cx='11' cy='11' r='5'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                                    background-size: 15px;
                                    background-repeat: no-repeat;
                                    background-position: left .2rem center;
                                    padding-left: 30px;
                                    display: flex;
                                }
                            }
                        }
                        .navigation-html {
                            a {
                                background-repeat: no-repeat;
                                background-position: center;
                                width: 30px;
                                height: 30px;
                                display: block;
                                margin-right: 10px;
                                &._fb {
                                    background-size: 15px;
                                    background-image: url("data:image/svg+xml,%0A%3Csvg width='400px' height='668px' viewBox='0 0 400 668' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='facebook-svgrepo-com' transform='translate(0.000000, 0.666667)' fill='%23303030' fill-rule='nonzero'%3E%3Cpath d='M266.666667,133.333333 L366.666667,133.333333 C385.076158,133.333333 400,118.409492 400,100 L400,33.3333333 C400,14.9238417 385.076158,0 366.666667,0 L266.666667,0 C174.619208,0 100,74.6192084 100,166.666667 L100,266.666667 L33.3333333,266.666667 C14.9238417,266.666667 0,281.590508 0,300 L0,366.666667 C0,385.076158 14.9238417,400 33.3333333,400 L100,400 L100,633.333333 C100,651.742825 114.923842,666.666667 133.333333,666.666667 L200,666.666667 C218.409492,666.666667 233.333333,651.742825 233.333333,633.333333 L233.333333,400 L307.333333,400 C323.023239,400.457121 336.906149,389.906109 340.666667,374.666667 L357.333333,308 C359.839488,297.882141 357.468342,287.174457 350.924888,279.060574 C344.381434,270.946692 334.419136,266.360904 324,266.666667 L233.333333,266.666667 L233.333333,166.666667 C233.333333,148.257175 248.257175,133.333333 266.666667,133.333333 Z' id='primary'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                                }
                                &._yt {
                                    background-size: 25px;
                                    background-image: url("data:image/svg+xml,%0A%3Csvg width='800px' height='801px' viewBox='0 0 800 801' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='youtube-svgrepo-com' transform='translate(0.000000, 0.183333)'%3E%3Cpolygon id='Path' points='0 0 800 0 800 800 0 800 0 4.6432765e-14'%3E%3C/polygon%3E%3Cpath d='M400,641.8 C380.483333,641.8 207.916667,641.433333 158.65,628.216667 C121.45513,618.231179 92.4021543,589.178204 82.4166667,551.983333 C68.9666667,501.866667 68.8333333,404.116667 68.8333333,399.983333 C68.8333333,395.85 68.9833333,298.1 82.4166667,247.983333 C92.4306895,210.795561 121.478894,181.747356 158.666667,171.733333 C207.916667,158.516667 380.483333,158.133333 400,158.133333 C419.516667,158.133333 592.083333,158.5 641.366667,171.716667 C678.549116,181.723649 707.593017,210.767551 717.6,247.95 L717.6,247.966667 L717.616667,247.966667 C731.033333,298.083333 731.166667,395.816667 731.166667,399.95 C731.166667,404.083333 731.016667,501.833333 717.6,551.95 C707.59526,589.149092 678.534611,618.20339 641.333333,628.2 C592.083333,641.416667 419.516667,641.8 400,641.8 L400,641.8 Z' id='Path' fill='%23303030' fill-rule='nonzero'%3E%3C/path%3E%3Cpath d='M348.116667,499.583333 C338.911921,499.583333 331.4499,492.121412 331.4499,482.916667 L331.4499,317.033333 C331.429391,311.070233 334.602486,305.552355 339.76675,302.570853 C344.931015,299.589351 351.296185,299.600471 356.45,302.6 L500.1,385.55 C505.256271,388.527324 508.4326,394.029209 508.4326,399.983333 C508.4326,405.937457 505.256271,411.439343 500.1,414.416667 L356.45,497.366667 C353.911216,498.815141 351.03959,499.578993 348.116667,499.583333 Z' id='Path' fill='%23FFFFFF' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                                }
                                &._ig {
                                    background-size: 20px;
                                    background-image: url("data:image/svg+xml,%0A%3Csvg width='444px' height='444px' viewBox='0 0 444 444' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='instagram-svgrepo-com' transform='translate(0.000000, 0.000000)' fill='%23303030' fill-rule='nonzero'%3E%3Cpath d='M362,104 C362,116 352,125 341,125 C329,125 320,115 320,104 C320,92 330,83 341,83 C352,82 362,92 362,104 Z M444,113 L444,331 C444,393 393,444 331,444 L113,444 C51,444 0,393 0,331 L0,113 C0,51 51,-2.84217094e-14 113,-2.84217094e-14 L331,-2.84217094e-14 C394,-2.84217094e-14 444,51 444,113 Z M402,113 C402,74 370,42 331,42 L113,42 C74,42 42,74 42,113 L42,331 C42,370 74,402 113,402 L331,402 C371,402 403,370 403,331 L402,113 Z M338,222 C338,158 286,106 222,106 C158,106 106,158 106,222 C106,286 158,338 222,338 C286,338 338,286 338,222 Z M296,222 C296,263 263,296 222,296 C181,296 148,263 148,222 C148,181 181,148 222,148 C263,148 296,181 296,222 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                                }
                                &._pi {
                                    background-size: 25px;
                                    background-image: url("data:image/svg+xml,%0A%3Csvg width='800px' height='800px' viewBox='0 0 800 800' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='pinterest-svgrepo-com-(1)'%3E%3Crect id='Rectangle' x='0' y='0' width='800' height='800'%3E%3C/rect%3E%3Cpath d='M408,80 C232,80 140,192 140,316 C140,376 172,436 224,468 C240,476 236,468 248,420 C248,416 248,412 244,408 C172,320 232,148 400,148 C644,148 596,484 444,484 C412,488 384,464 384,432 L384,416 C400,372 412,332 416,288 C416,204 292,216 292,328 C292,348 296,368 304,384 C304,384 264,548 256,576 C248,624 252,672 260,716 C256,720 260,720 260,720 L264,720 C292,680 316,640 332,596 C336,576 356,504 356,504 C376,532 412,548 448,548 C572,548 660,440 660,308 C660,176 548,80 408,80 Z' id='Path' fill='%23303030' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                                }
                                &._tk {
                                    background-image: url("data:image/svg+xml,%3Csvg fill='%23000000' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Ctitle%3Etiktok%3C/title%3E%3Cpath d='M16.656 1.029c1.637-0.025 3.262-0.012 4.886-0.025 0.054 2.031 0.878 3.859 2.189 5.213l-0.002-0.002c1.411 1.271 3.247 2.095 5.271 2.235l0.028 0.002v5.036c-1.912-0.048-3.71-0.489-5.331-1.247l0.082 0.034c-0.784-0.377-1.447-0.764-2.077-1.196l0.052 0.034c-0.012 3.649 0.012 7.298-0.025 10.934-0.103 1.853-0.719 3.543-1.707 4.954l0.020-0.031c-1.652 2.366-4.328 3.919-7.371 4.011l-0.014 0c-0.123 0.006-0.268 0.009-0.414 0.009-1.73 0-3.347-0.482-4.725-1.319l0.040 0.023c-2.508-1.509-4.238-4.091-4.558-7.094l-0.004-0.041c-0.025-0.625-0.037-1.25-0.012-1.862 0.49-4.779 4.494-8.476 9.361-8.476 0.547 0 1.083 0.047 1.604 0.136l-0.056-0.008c0.025 1.849-0.050 3.699-0.050 5.548-0.423-0.153-0.911-0.242-1.42-0.242-1.868 0-3.457 1.194-4.045 2.861l-0.009 0.030c-0.133 0.427-0.21 0.918-0.21 1.426 0 0.206 0.013 0.41 0.037 0.61l-0.002-0.024c0.332 2.046 2.086 3.59 4.201 3.59 0.061 0 0.121-0.001 0.181-0.004l-0.009 0c1.463-0.044 2.733-0.831 3.451-1.994l0.010-0.018c0.267-0.372 0.45-0.822 0.511-1.311l0.001-0.014c0.125-2.237 0.075-4.461 0.087-6.698 0.012-5.036-0.012-10.060 0.025-15.083z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
                                    background-size: 25px;
                                }
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //.newsletter__container-header {
    //    h4 {
    //        font-size: 14px;
    //        text-transform: uppercase;
    //        font-weight: 600;
    //        margin: 0 0 10px;
    //    }
    //    @include breakpoint(small only) {
    //        margin: 80px 0 0;
    //    }
    //}
    .store__rating {
        position: relative;
        ._container {
            transform: scale(0.7) translate(-50%);
            position: absolute;
            left: 10%;
            top: -30px;
            @include breakpoint(small only) {
                transform: scale(.6) translateX(50%);
                left: -50%;
                top: -70px;
            }
            background: color(base, White);
            height: 78px;
            width: 344px;
            display: inline-block;
            border-radius: 40px 18px 18px 40px;
        }
    }

    .absolute__footer-container {
        padding: 12px 0;
        background: color(base, White);
        @include breakpoint(small only) {
            padding: 15px 0;
        }
        .grid-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            @include breakpoint(small only) {
                position: relative;
                padding-top: 56px;
            }

            iframe {
                height: 85px;
                transform: scale(0.6);
                transform-origin: left;
                overflow: hidden;
                @include breakpoint(small only) {
                    transform: scale(.5);
                    transform-origin: left;
                    position: absolute;
                    left: 24px;
                    top: -17px;
                }
            }
            div {
                display: flex;
                flex-wrap: wrap;
                font-size: 12px;
                padding: 0 6px;
                align-items: center;
                @include breakpoint(medium) {
                    line-height: 30px;
                    justify-content: flex-start;
                }
                a,
                span {
                    margin-left: 24px;
                    display: inline;

                    @include breakpoint(small only) {
                        margin-bottom: 12px;
                        margin-left: 0;
                        margin-right: 12px;
                    }
                }
                a:first-child {
                    margin-right: 10px;
                }
            }
        }
    }

    .scrolltotop {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 9999;
        cursor: pointer;
        width: 40px;
        height: 40px;
        background: color(base, White);
        border-radius: 50%;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease-in-out;
        &.hidden {
            display: none;
        }
        &:hover {
            background: color(base, White);
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
        }
        .scrolltotop__icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 20px;
            height: 20px;
            background-size: 20px;
        }
    }
}

.no-link {
    &:hover {
        text-decoration: none;
        cursor: auto;
    }
}
