.header__navigation {
    background: color(base, White);
    position: relative;
    padding: 0;
    display: table-cell;
    vertical-align: middle;
    nav {
        width: 100%;
        > ul {
            display: block;
            width: 100%;
            > li {
                display: inline-block;
                margin-right: 24px;
                line-height: 55px;
                @include breakpoint(medium) {
                    margin-right: 0;
                    margin-left: 24px;
                }
                @include breakpoint(xlarge) {
                    margin-right: 24px;
                    margin-left: 0;
                }
                .home-icon-item {
                    display: inline-flex;
                    align-items: center;
                    &:hover {
                        text-decoration: none;
                        &:before {
                            content: "";
                            position: absolute;
                            bottom: 40%;
                            background: color(grey, Black);
                            height: 3px;
                            left: 0;
                            right: 97%;
                        }
                    }
                }
                .menu-item {
                    position: relative;
                    display: block;
                    color: color(grey, Black);
                    font-weight: 400;
                    text-align: center;
                    &:hover {
                        text-decoration: none;
                        &:before {
                            content: "";
                            position: absolute;
                            bottom: 25%;
                            left: 0;
                            right: 0;
                            height: 3px;
                            background: color(grey, Black);
                        }
                    }
                }
                &._sale a {
                    color: color(base, Primary);
                }
            }
        }
    }
}

.nav__overlay {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 40px;
    bottom: 0;
    z-index: 9;
    background: rgba(255, 197, 3, 0.2);
    @include breakpoint(large) {
        &._active {
            display: block;
        }
    }
}

.off-canvas.position-left {
    width: 90%;

    &:not(.in-canvas-for-medium):not(.is-open) {
        transform: translateX(-100%);
    }

    .modal__header {
        display: block;
        background: color(base, White);
        position: relative;
        border-bottom: 1px solid color(grey, SoftGrey);

        .title {
            display: block;
            line-height: 50px;
            text-align: center;
            color: color(grey, Black);
            font-weight: 500;
        }

        button {
            position: absolute;
            top: 0;
            left: 15px;
            color: color(grey, Black);
            line-height: 50px;
        }
    }

    .modal__header + .drilldown {
        background: color(base, White);
        height: calc(100vh - 100px);
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 100px;
        .menu.is-active {
            background: color(base, White);
            height: calc(100vh + 51px);
            //overflow-y: auto;
            //overflow-x: hidden;
            //padding-bottom: 200px;
            //@include breakpoint(small only) {
            //    padding-bottom: 0;
            //}
        }
        .invisible {
            display: none;
        }
    }

    .drilldown {
        li {
            background-color: color(base, White);
            &.is-drilldown-submenu-parent {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E&gt;%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='&gt;'%3E%3Ccircle id='Oval-Copy' fill='%23F5F6F4' cx='15' cy='15' r='15'%3E%3C/circle%3E%3Cpolygon id='Page-1' stroke='%23231F20' stroke-width='1.5' transform='translate(15.853638, 15.365854) scale(-1, 1) translate(-15.853638, -15.365854) ' points='18.2925752 10.2439024 13.1707317 15.3657459 18.2925752 20.4878049 18.5365443 20.2436202 13.6588855 15.3657459 18.5365443 10.4880871'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: right 0.9375em center;
                background-size: 18px;
            }
            .js-drilldown-back {
                padding: 10px 10px 0;
                font-size: 14px;
            }
            a {
                border-bottom: 1px solid color(grey, SoftGrey);
                font-weight: 500;
            }
            &._sale a {
                color: color(base, Primary);
            }
        }
    }
}

.off-canvas.position-left .drilldown li .js-drilldown-back {
    padding: 0;
    button {
        line-height: 48px;
        height: 48px;
        border-bottom: 1px solid color(grey, SoftGrey);
        font-weight: 500;
        display: block;
        width: 100%;
        text-align: left;
        padding: 0 1.5rem 0 2.5rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m15 19-7-7 7-7'/%3E%3C/svg%3E");
        background-position: left 1rem center;
        background-repeat: no-repeat;
        background-size: 1rem;
    }
}

.menu > ul {
    button {
        display: block;
    }
}

.navigation-mobile--right {
    a {
        border-bottom: none;
    }
    p {
        line-height: 50px;
        font-size: 13px;
        font-weight: 500;
    }
}

.main__navigation .navigation-right {
    a p {
        font-weight: 400 !important;
    }
}


.main__navigation, .footer_menu {
    a.nav-light, p.nav-light {
        color: color(base, LightNav);
    }
    a.sale, p.sale {
        color: color(base, Sale);
    }
    a.new, p.new {
        color: color(base, New);
    }
    a.exclusive, p.exclusive {
        color: color(base, Exclusive);
    }

}
