.slid {
    border: 1px solid red;
}

.cont {
    border: 1px solid red;
}

.slider-img {
    width: 100%;
}

.swiper-pagination-bullets {
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 20px 0 0;

    .swiper-pagination-bullet {
        display: block;
        height: 8px;
        width: 8px;
        background: color(grey, MediumGrey);
        margin: 0 4px;
        border-radius: 50%;

        &.swiper-pagination-bullet-active {
            background: color(grey, Black);
        }
    }
}

.slider__controls {
    &:not(._mobile) {
        right: 0;
        top: 0;
        display: none;
        @include breakpoint(1610px) {
            display: flex;
        }
    }

    .slider__control {
        @include transition;
        cursor: pointer;
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        z-index: 2;
        @include breakpoint(small only) {
            width: 20px;
            height: 20px;
        }
        &.swiper-button-disabled {
            pointer-events: none;
            opacity: 0.5;
        }
        &:after {
            display: none;
        }

        &.swiper-button-prev {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='23px' height='41px' viewBox='0 0 23 41' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPage 1 Copy%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Page-1-Copy' stroke='%23989898' fill='%23909090' points='21.0451862 -1.13686838e-13 1 20.0451862 21.0451862 40.0912158 22 39.1355585 2.91047114 20.0451862 22 0.955657308'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
            position: absolute;
            top: 30%;
            left: -100px;
            @include breakpoint(medium down) {
                left: 0;
            }

        }

        &.swiper-button-next {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='23px' height='41px' viewBox='0 0 23 41' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPage 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon stroke='%23989898' fill='%23909090' transform='translate(11.500000, 20.045608) scale(-1, 1) translate(-11.500000, -20.045608) ' points='21.0451862 0 1 20.0451862 21.0451862 40.0912158 22 39.1355585 2.91047114 20.0451862 22 0.955657308'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
            position: absolute;
            top: 30%;
            right: -100px;
            @include breakpoint(medium down) {
                right: -10px;
            }
        }
    }

    &._mobile {
        .slider__control {
            z-index: 9;
            position: absolute;
            top: 30%;
            width: 30px;
            height: 30px;

            &.swiper-button-prev {
                left: -20px;
                background-position: right 11px center;
            }

            &.swiper-button-next {
                right: -10px;
                background-position: left 11px center;
            }
        }
    }

    &._white {
        .slider__control {
            background-color: color(base, White);

            &:hover {
                background-color: color(grey, SoftGrey);
            }
        }
    }
}
