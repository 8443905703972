.quote-modal {
    background: rgba(0, 0, 0, .2);
    inset: 0;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 10;

    input[type="checkbox"] {
        margin-left: 3px;
    }

    textarea {
        padding: 0 5px;

        &::placeholder {
            font-size: 10px;
            line-height: unset;
            @include breakpoint(medium up) {
                font-size: 12px;
            }
        }
    }

    .newsletter-opt {
        line-height: 16px;
    }

    .thank-you {
        font-size: 16px;
        padding-left: 1rem;
        max-width: 90%;
        display: inline-block;
        margin: 1rem auto 0;
    }

    .field-error-message {
        color: color(alert, Error);
        margin-top: .3rem;
    }

    .quote-modal-close {
        position: absolute;
        @include breakpoint(medium up) {
            right: 20px;
        }
        @include breakpoint(medium down) {
            right: 40px;
            font-size: 20px;
        }
        top: 10px;
        font-size: 16px;
        cursor: pointer;
    }

    .quote-modal-submit {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        ._quote_modal-submit {
            max-width: 300px;
            align-self: end;
            @include breakpoint(medium down) {
                margin-right: 40px;
            }
            @include breakpoint(medium up) {
                margin-right: 10px;
            }
            border: color(alert, Warning);
            background: color(alert, Warning);
            font-size: 16px;
            margin-bottom: .3rem;
            cursor: pointer;
        }

        .response-time {
            align-self: end;
            @include breakpoint(medium down) {
                margin-right: 40px;
            }
            @include breakpoint(medium up) {
                margin-right: 10px;
            }
            font-size: 9px;
        }
    }

    h3 {
        margin-top: 1rem;
        color: color(base, Primary);
        font-style: italic;
        font-size: 16px;
    }

    h3, h4 {
        padding-left: 1rem;
        margin-bottom: .3rem;
    }

    h4 {
        font-weight: 700;
    }

    span {
        font-size: 12px;
        margin-top: .3rem;
    }

    &-field {
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
        margin: .75rem 0;

        &._full {
            width: 90%;
        }

        &._small {
            width: 50%;
        }

        label {
            width: 100%;
            margin-bottom: .3rem;
            font-size: 12px;
        }

        input[type='text'], select, textarea {
            transition: .3s all;
            border: 1px solid color(grey, SoftGrey);
            color: #737d8a;
            height: 34px;

            &:hover {
                border: 1px solid #000;
            }
        }

        select {
            line-height: unset;
            padding-left: 10px;
        }
    }
}

.quote-form {
    background: #fff;
    margin: 0 auto;
    min-width: 400px;
    position: relative;
    width: 40%;
    padding: 1rem 0;
    border-radius: 1px;
    overflow-y: scroll;
    @include breakpoint(medium up) {
        top: 20px;
    }
    @include breakpoint(medium down) {
        height: 100%;
        top: 0;
    }
}
