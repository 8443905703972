.error-page_body {
    .breadcrumbs {
        display: none;
    }

    .cms-page {
        margin: 20px 0 0;
    }
}

.cms-page {
    .content {
        h1 {
            margin: 0 0 20px;
        }

        h2 {
            font-size: 26px;
            line-height: 32px;
            font-weight: 600;
            margin: 0 0 20px;
        }

        h3 {
            font-size: 22px;
            line-height: 28px;
            font-weight: 600;
            margin: 0 0 20px;
        }

        h4 {
            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
            margin: 0 0 20px;
        }

        p {
            font-size: 15px;
            line-height: 23px;
            margin: 0 0 20px;
        }

        a {
            font-weight: 500;
            text-decoration: none;
            color: color(primary, DarkBlue);
            &:hover {
                color: color(primary, PrimaryBlue);
            }
        }

        ul, ol {
            list-style: disc inside;
            margin: 0 0 20px;
            font-size: 15px;
            line-height: 23px;
        }

        ol {
            list-style: decimal inside;
        }

        table {
            width: 100%;
            margin: 0 0 20px;
            background: color(base, White);

            tr {
                &:nth-child(even) {
                    background: color(base, WhiteSecond);
                }

                th, td {
                    text-align: left;
                    border: 1px solid color(grey, MediumGrey);
                    border-collapse: collapse;
                    padding: 4px 6px;
                }
            }
        }
    }

    &.rich-content {
        h1, h2, h3, h4 {
            &:not(.run-wild) {
                display: flex;
                align-items: center;
            }
        }

        .content-block {
            margin: 0 0 40px;

            h1, h2, h3, h4 {
                margin: 0 0 15px;
            }

            &.image-blocks {
                a {
                    display: block;
                    overflow: hidden;

                    &:hover {
                        img {
                            transform: scale(1.1);
                        }
                    }

                    img {
                        width: 100%;
                        transition: transform .1s ease-in-out;
                    }

                    &:last-child {
                        line-height: 21px;
                        display: block;
                        padding: 15px 10px;
                        text-align: center;
                        @include breakpoint(small only) {
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }
                }
            }

            &.image-overlay {
                ._holder {
                    position: relative;
                    @include breakpoint(small only) {
                        margin: 0 0 15px;
                    }

                    img {
                        width: 100%;
                    }

                    ._content {
                        position: absolute;
                        background: color(base, White);
                        padding: 20px;
                        bottom: 35px;
                        left: 35px;
                        right: 35px;
                        text-align: center;

                        h3 {
                            font-size: 21px;
                            line-height: 26px;
                            margin: 0 0 10px;
                            text-align: center;
                            display: block;
                            text-transform: initial;
                            @include breakpoint(large down) {
                                font-size: 18px;
                            }

                            &:after {
                                display: none;
                            }
                        }

                        p {
                            line-height: 21px;
                            @include breakpoint(large down) {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            &.featured {
                ._container {
                    text-align: center;
                    padding: 130px 20px 0;
                    @include breakpoint(medium only) {
                        padding: 25px 20px 0;
                    }
                    @include breakpoint(small only) {
                        padding: 50px 20px 0;
                    }

                    h3 {
                        font-size: 16px;
                        display: inline-block;
                        line-height: 26px;
                        color: color(grey, DarkerGrey);
                        margin: 0 0 15px;
                    }

                    span {
                        display: block;
                    }

                    p {
                        max-width: 50%;
                        margin: 30px auto 0;
                        line-height: 26px;
                        @include breakpoint(medium only) {
                            margin: 20px auto 0;
                            font-size: 14px;
                        }
                        @include breakpoint(small only) {
                            max-width: 90%;
                        }
                    }

                    a {
                        @include default-button;
                        background: transparent;
                        border: 1px solid color(grey, Black);
                        color: color(grey, Black);
                        margin: 15px 0 0;
                        @include breakpoint(small only) {
                            border-radius: 0;
                        }

                        &:hover {
                            background: transparent;
                            border-color: color(base, Black);
                            color: color(base, Black);
                        }
                    }
                }
            }
        }

        .shop-de-look {
            .holder {
                position: relative;
                margin: 0 0 30px;
                @include breakpoint(small only) {
                    margin: 0 0 15px;
                }

                img {
                    width: 100%;
                }

                ._content {
                    position: absolute;
                    background: color(base, White);
                    padding: 50px 40px;
                    top: 35px;
                    left: 35px;
                    right: 50%;
                    text-align: center;
                    @include breakpoint(small only) {
                        left: 15px;
                        top: 20px;
                        padding: 20px;
                    }

                    p {
                        @include breakpoint(small only) {
                            margin: 0;
                        }
                    }

                    h3 {
                        text-align: center;
                        display: block;
                        font-size: 50px;
                        line-height: 60px;
                        border-bottom: 1px solid color(grey, DarkerGrey);
                        padding: 0 0 10px;
                        @include breakpoint(small only) {
                            font-size: 30px;
                            line-height: 40px;
                        }

                        &:after {
                            display: none;
                        }
                    }
                }
            }

            ._holder-item {
                position: relative;
                margin: 0 0 25px;

                img {
                    width: 100%;
                }

                h4 {
                    font-size: 28px;
                    margin: 0;
                    line-height: 40px;
                }

                p {
                    margin: 0;
                }
            }
        }

        .new-products {
            .holder {
                position: relative;
                margin: 0 0 15px;
                @include breakpoint(small only) {
                    margin: 0 0 15px;
                }

                img {
                    width: 100%;
                    height: auto;
                }

                ._content {
                    position: absolute;
                    bottom: 35px;
                    left: 35px;

                    h4 {
                        border-radius: 50%;
                        width: 2em;
                        height: 2em;
                        border: 1px solid color(base, White);
                        background: color(base, LightBlue);
                        margin: 0;
                        text-align: center;
                        color: color(base, White);
                        padding: 12px;
                        box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

                        &:after {
                            display: none;
                        }
                    }
                }
            }

            ._content._text {
                padding: 0 75px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;

                h2 {
                    text-align: center;
                    font-size: 30px;
                    font-weight: 300;
                    color: color(grey, DarkerGrey);

                    &:after {
                        display: none;
                    }

                    &.run-wild {
                        font-size: 50px;
                        margin: 0 0 10px;
                        color: color(base, black);
                    }
                }

                ol {
                    display: inline-block;
                    text-align: center;

                    li {
                        display: inline-block;
                    }
                }
            }
        }

        .samenstellen {
            .holder {
                position: relative;
                margin: 0 0 15px;
                @include breakpoint(small only) {
                    margin: 0 0 15px;
                }

                img {
                    width: 100%;
                    height: auto;
                }

                ._content {
                    position: absolute;
                    background: color(base, White);
                    padding: 40px;
                    top: 50px;
                    left: 50px;
                    right: 50px;
                    text-align: center;
                    @include breakpoint(small only) {
                        padding: 20px;
                        left: 20px;
                        right: 20px;
                        top: 20px;
                    }

                    p {
                        color: color(grey, DarkerGrey);
                        @include breakpoint(small only) {
                            font-size: 14px;
                            margin: 0 0 10px;
                        }
                    }

                    h3 {
                        text-align: center;
                        display: block;
                        font-size: 26px;
                        color: color(grey, DarkerGrey);
                        @include breakpoint(small only) {
                            font-size: 18px;
                            margin: 0 0 10px;
                        }

                        &:after {
                            display: none;
                        }

                        &.run-wild {
                            font-size: 50px;
                            color: color(base, Black);
                            line-height: 60px;
                            @include breakpoint(small only) {
                                font-size: 28px;
                                line-height: 40px;
                            }
                        }
                    }
                }
            }

            ._total-steps {
                counter-reset: number;
                list-style-type: none;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                height: 100%;

                ._step {
                    position: relative;
                    margin: 0 0 0 40px;

                    h3 {
                        display: inline-block;
                        margin: 0 0 10px;
                        font-size: 30px;
                    }

                    &:before {
                        counter-increment: number;
                        content: counter(number) "\a0";
                        position: absolute;
                        display: block;
                        border-radius: 50%;
                        width: 2em;
                        height: 2em;
                        border: 1px solid color(base, White);
                        background: color(base, LightBlue);
                        text-align: center;
                        top: 0;
                        left: -40px;
                        z-index: -1;
                        color: color(base, White);
                        padding: 3px 10px;
                        box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
                    }
                }
            }
        }
    }
}
