.contact-form_body {
    .breadcrumbs {
        display: none;
    }
    .contact__page-content {
        .contact__header {
            background: color(primary, OffWhite);
            padding: 40px 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            span {
                display: block;
                line-height: 26px;
                font-size: 24px;
                font-weight: 200;
                @include breakpoint(small only) {
                    font-size: 20px;
                }
                &._bold {
                    font-weight: bold;
                }
            }
        }
        .contact__page-grid {
            .contact__header--info {
                margin: 60px 0;
                @include breakpoint(small only) {
                    margin: 40px 0;
                }
                h1 {
                    margin: 0 0 10px;
                    @include breakpoint(small only) {
                        font-size: 20px;
                    }
                }
                p {
                    @include breakpoint(small only) {
                        font-size: 14px;
                    }
                }
            }
            .contact__block {
                background: color(primary, OffWhite);
                padding: 20px;
                margin-bottom: 40px;
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 350px;
                &:nth-of-type(odd) {
                    margin-right: 20px;
                    @include breakpoint(small only) {
                        margin-right: 0;
                    }
                }
                h4 {
                    font-size: 21px;
                    line-height: 30px;
                    @include breakpoint(small only) {
                        font-size: 18px;
                    }
                }
                img {
                    margin: 20px 0 30px;
                }
                ._info {
                    text-align: center;
                    p {
                        font-size: 16px;
                        line-height: 30px;
                        font-weight: 200;
                        @include breakpoint(small only) {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                    a {
                        line-height: 30px;
                        font-weight: bold;
                    }
                }
            }
            .contact--right {
                .contact--opening-times {
                    margin: 0 0 50px;
                    h4 {
                        font-size: 21px;
                        font-weight: bold;
                        margin: 0 0 15px;
                    }
                    ul {
                        li {
                            display: flex;
                            line-height: 46px;
                            @include breakpoint(small only) {
                                line-height: 30px;
                            }
                            span {
                                flex: 1;
                            }
                        }

                    }
                }
                .contact--additional-info {
                    h4 {
                        font-size: 21px;
                        font-weight: bold;
                        margin: 0 0 15px;
                    }
                    p {
                        font-size: 16px;
                        margin: 0 0 10px;
                    }
                }
            }
        }
        .contact__usp {
            background: color(primary, PrimaryBlue);
            margin: 60px 0 40px;
            ul {
                display: flex;
                justify-content: space-around;
                li {
                    line-height: 60px;
                    font-size: 16px;
                    color: color(base, White);
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    @include breakpoint(medium) {
                        line-height: 60px;
                        font-size: 14px;
                        &:last-of-type {
                            display: none;
                        }
                    }
                    @include breakpoint(large) {
                        line-height: 60px;
                        font-size: 18px;
                    }
                    &::before {
                        content: '';
                        width: 23px;
                        height: 23px;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='%23fff' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E%0A");
                        display: inline-block;
                        margin-right: 6px;
                    }
                }
            }
        }
        .contact__links {
            margin: 100px 0;
            @include breakpoint(small only) {
                margin: 40px 0 0;
            }
            .cell {
                @include breakpoint(small only) {
                    margin: 0 0 30px;
                }
            }
            h4 {
                font-size: 24px;
                line-height: 30px;
                font-weight: 500;
                margin: 0 0 15px;
                @include breakpoint(small only) {
                    font-size: 20px;
                    margin: 0 0 10px;
                }
            }
            ._links {
                &._more {
                    a {
                        display: block;
                    }
                }
                a {
                    display: inline-block;
                    text-decoration: underline;
                    line-height: 30px;
                    padding-left: 30px;
                    &._truck {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12' /%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-size: 22px;
                        background-position: left center;
                    }
                    &._euro {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z' /%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-size: 22px;
                        background-position: left center;
                    }
                    &._agenda {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5' /%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-size: 22px;
                        background-position: left center;
                    }
                    &._question {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z' /%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-size: 22px;
                        background-position: left center;
                    }
                }
                span {
                    line-height: 30px;
                }
            }
        }
    }
}
