header {
    .sticky.is-at-top.is-stuck {
        .main__navigation {
            box-shadow: 0 2px 5px -5px #222;
        }
        //.top__header {
        //    display: none;
        //}
    }
    .top__header {
        background: color(base, White);
        transition: all .2s ease-in-out;
        position: relative;
        .rating-container {
            display: flex;
            gap: 8px;
            align-items: center;
            height: 100%;
            .rating-container-fill {
                display: block;
                width: 94px;
                height: 16px;
                background-size: cover;
                margin-left: auto;
                background-image: url("data:image/svg+xml,%3Csvg width='70' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23FFD39A' fill-rule='nonzero'%3E%3Cpath d='M6.614 0 4.305 3.601 0 4.583l2.877 3.208L2.526 12l4.088-1.619L10.701 12l-.351-4.209 2.877-3.208-4.304-.982L6.614 0zM20.807 0l-2.309 3.601-4.305.982 2.877 3.208L16.719 12l4.088-1.619L24.894 12l-.35-4.209 2.877-3.208-4.305-.982L20.807 0zM35 0l-2.309 3.601-4.305.982 2.877 3.208L30.912 12 35 10.381 39.087 12l-.35-4.209 2.877-3.208-4.305-.982L35 0zM49.193 0l-2.309 3.601-4.305.982 2.877 3.208L45.105 12l4.088-1.619L53.281 12l-.351-4.209 2.877-3.208-4.304-.982L49.193 0zM63.386 0l-2.309 3.601-4.304.982 2.877 3.208L59.299 12l4.087-1.619L67.474 12l-.351-4.209L70 4.583l-4.304-.982L63.386 0z'/%3E%3C/g%3E%3C/svg%3E");
                .--fill {
                    display: block;
                    height: 16px;
                    background-size: cover;
                    background-image: url("data:image/svg+xml,%3Csvg width='70' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ed6d34' fill-rule='nonzero'%3E%3Cpath d='M6.614 0 4.305 3.601 0 4.583l2.877 3.208L2.526 12l4.088-1.619L10.701 12l-.351-4.209 2.877-3.208-4.304-.982L6.614 0zM20.807 0l-2.309 3.601-4.305.982 2.877 3.208L16.719 12l4.088-1.619L24.894 12l-.35-4.209 2.877-3.208-4.305-.982L20.807 0zM35 0l-2.309 3.601-4.305.982 2.877 3.208L30.912 12 35 10.381 39.087 12l-.35-4.209 2.877-3.208-4.305-.982L35 0zM49.193 0l-2.309 3.601-4.305.982 2.877 3.208L45.105 12l4.088-1.619L53.281 12l-.351-4.209 2.877-3.208-4.304-.982L49.193 0zM63.386 0l-2.309 3.601-4.304.982 2.877 3.208L59.299 12l4.087-1.619L67.474 12l-.351-4.209L70 4.583l-4.304-.982L63.386 0z'/%3E%3C/g%3E%3C/svg%3E");
                }
            }
            .rating-integer {
                font-size: 12px;
                font-weight: 500;
                line-height: 40px;
            }
            a {
                font-size: 12px;
                line-height: 40px;
                color: color(primary, CtaOrange);
            }
        }
        .top__links {
            .opening__times {
                color: color(primary, DarkBlue);
                font-size: 14px;
                font-weight: 500;
                ._is-open {
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background-color: color(primary, CtaGreen);
                    display: inline-block;
                    top: -1px;
                    position: relative;
                    margin-left: 5px;
                }
                ._is-closed {
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background-color: color(primary, CtaOrange);
                    display: inline-block;
                    top: -1px;
                    position: relative;
                    margin-left: 5px;
                }
            }
        }
        .top__usp {
            ul {
                display: flex;
                @include breakpoint(small only) {
                    justify-content: center;
                }
                li {
                    font-size: 12px;
                    line-height: 40px;
                    color: color(primary, DarkBlue);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23a6cc4d' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 16px;
                    background-position: 0;
                    padding-left: 24px;
                    margin-right: 15px;
                    @include breakpoint(1440px) {
                        font-size: 12px;
                    }
                    @include breakpoint(small only) {
                        line-height: 22px;
                    }
                    &:nth-child(3) {
                        display: none;
                        @include breakpoint(426px) {
                            display: block;
                        }
                    }
                    &:nth-child(2) {
                        display: none;
                        @include breakpoint(920px) {
                            display: block;
                        }
                    }
                    &:last-child {
                        display: none;
                        @include breakpoint(1230px) {
                            display: block;
                        }
                    }
                }
            }
        }

        .top__links {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            font-size: 12px;
            color: color(primary, DarkBlue);
            height: 100%;
            .opening__times {
                align-self: center;
            }
            @include breakpoint(1440px) {
                font-size: 14px;
            }
            p {
                align-self: center;
            }
            > span {
                line-height: 40px;
                color: color(base, White);
                margin-right: 25px;
            }
            ul {
                display: flex;
                li a {
                    display: block;
                    line-height: 40px;
                    color: color(base, White);
                    margin-left: 15px;
                    &._phone {
                        width: 18px;
                        height: 18px;
                        background-image: url('../assets/images/phone.png');
                        background-size: contain;
                        margin: 11px 0 11px 15px;
                    }
                    &._mail {
                        width: 21px;
                        height: 15px;
                        background-image: url('../assets/images/mail.png');
                        background-size: contain;
                        margin: 0.781em 0 0.781em 15px;
                    }
                }
            }
        }
    }

    .header-container {
        padding: 20px 0;
        background: color(primary, PrimaryBlue);
        @include breakpoint(small only) {
            padding: 10px 0;
        }
        .header__icons {
            justify-content: flex-end;
            ._secondary-menu {
                color: color(base, White);
                display: flex;
                align-items: center;
                @include breakpoint(small only) {
                    display: none;
                }
                li {
                    padding: 0 12px;
                    a {
                        color: color(base, white);
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 500;
                        p {
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
        .header__mobile {
            padding-right: 0;
            align-self: center;
            .mobile__header {
                display: flex;
                padding: 8px 0;
                .menu__toggle {
                    width: 17px;
                    height: 12px;
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='19px' height='12px' viewBox='0 0 19 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EHamburger%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cg id='Hamburger' transform='translate(1.000000, 0.000000)' stroke='%23ffffff' stroke-width='1.5'%3E%3Cline x1='0' y1='1' x2='17' y2='1' id='Line'%3E%3C/line%3E%3Cline x1='0' y1='5.5' x2='17' y2='5.5' id='Line'%3E%3C/line%3E%3Cline x1='0' y1='10.5' x2='17' y2='10.5' id='Line'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    cursor: pointer;
                }
                .toggle__search {
                    display: block;
                    width: 15px;
                    height: 16px;
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='17px' viewBox='0 0 16 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ESearch%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Search' transform='translate(1.000000, 1.000000)' stroke='%23F4F4F4' stroke-width='1.5'%3E%3Cg id='Group-3' transform='translate(0.156284, 0.150321)'%3E%3Cline x1='9.20117477' y1='10.0211377' x2='13.5080446' y2='14.3280075' id='Stroke-3'%3E%3C/line%3E%3Cpath d='M10.9714286,5.48571429 C10.9714286,8.51515287 8.51610974,10.9714286 5.48571429,10.9714286 C2.4562757,10.9714286 0,8.51515287 0,5.48571429 C0,2.45531883 2.4562757,0 5.48571429,0 C8.51610974,0 10.9714286,2.45531883 10.9714286,5.48571429 Z' id='Stroke-1'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    margin-left: 15px;
                }
            }
        }
        @include breakpoint(small only) {
            .header__icons {
                order: 2;
            }
            .header__search {
                order: 3;
            }
        }
        ._logo {
            display: flex;
            height: 100%;
            @include breakpoint(small only) {
                justify-content: center;
                img {
                    height: 28px;
                    width: auto;
                }
            }
        }
        .header__navigation {
            @include breakpoint(large down) {
                display: flex;
                justify-content: flex-end;
            }
            ul {
                display: flex;
                margin: 10px 0;
                li:last-child a {
                    margin-right: 0;
                }
                li a {
                    position: relative;
                    display: block;
                    line-height: 40px;
                    margin-right: 10px;
                    font-size: 14px;
                    @include breakpoint(1230px) {
                        font-size: 16px;
                        margin-right: 20px;
                    }
                    &._home {
                        margin: 8px 20px 8px 0;
                        width: 17px;
                        height: 20px;
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='17px' height='20px' viewBox='0 0 17 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EFill 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Fill-1' stroke='%23303030' points='8.5 1 1 7.94688314 1 19 16 19 16 7.94688314'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                    }
                    &:hover {
                        text-decoration: none;
                        &:after {
                            content: '';
                            height: 3px;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 5px;
                            background: color(base, Black);
                        }
                        &._home:after {
                            bottom: -7px;
                        }
                    }
                }
            }
        }

        .search-form__holder {
            position: relative;
            margin: 8px 0;
            @include breakpoint(small only) {
                margin: 10px 0 0;
            }
            button {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                cursor: pointer;
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='23px' viewBox='0 0 22 23' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EGroup%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(1.000000, 1.000000)' stroke='%23303030'%3E%3Cline x1='13.4183799' y1='14.6141591' x2='19.6992317' y2='20.8950109' id='Stroke-3'%3E%3C/line%3E%3Cpath d='M16,8 C16,12.4179313 12.4193267,16 8,16 C3.58206872,16 0,12.4179313 0,8 C0,3.58067329 3.58206872,0 8,0 C12.4193267,0 16,3.58067329 16,8 Z' id='Stroke-1'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                width: 44px;
                height: 44px;
                @include breakpoint(small only) {
                    width: 32px;
                    height: 32px;
                    background-size: 18px;
                }
            }
        }

        .header__links {
            margin: 19px 0;
            display: flex;
            @include breakpoint(small only) {
                margin: 0 0 0 auto;
                flex-wrap: wrap;
                align-items: center;
            }
            li a {
                display: block;
                margin-left: 15px;
                position: relative;
                background-repeat: no-repeat;
                @include breakpoint(small only) {
                    margin-left: 2px;
                    top: -2px;
                }
                &._delivery {
                    width: 24px;
                    height: 21px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='white' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12' /%3E%3C/svg%3E%0A");
                    margin-top: 2px;
                }
                &._customer {
                    width: 24px;
                    height: 21px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='white' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z' /%3E%3C/svg%3E%0A");
                }
                &._wishlist {
                    width: 24px;
                    height: 21px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='white' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z' /%3E%3C/svg%3E%0A");
                }
                &._cart {
                    width: 24px;
                    height: 21px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='white' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z' /%3E%3C/svg%3E%0A");
                }
                ._amount {
                    position: absolute;
                    display: block;
                    width: 18px;
                    height: 18px;
                    background: color(primary, CtaOrange);
                    color: color(base, White);
                    border-radius: 50%;
                    text-align: center;
                    line-height: 18px;
                    font-size: 11px;
                    font-weight: 600;
                    top: -8px;
                    right: -8px;
                    @include breakpoint(small only) {
                        width: 15px;
                        height: 15px;
                        line-height: 15px;
                        font-size: 9px;
                    }
                }
            }
            .header__cart-container {
                position: relative;
                ._dropdown {
                    position: absolute;
                    z-index: 9;
                    background: color(base, White);
                    width: 350px;
                    right: -10px;
                    top: 25px;
                    border: 1px solid color(grey, ExtraSoftGrey);
                    ._added {
                        background: color(base, White);
                        border-bottom: 1px solid color(grey, ExtraSoftGrey);
                        p {
                            color: color(alert, Success);
                            font-size: 13px;
                            text-align: center;
                            font-weight: 500;
                            margin: 0 auto;
                            line-height: 30px;
                            padding: 0 10px;
                        }
                    }
                    .cart__items {
                        font-size: 14px;
                        ul {
                            li {
                                border-bottom: 1px solid color(grey, ExtraSoftGrey);
                                a {
                                    padding: 5px 10px;
                                    display: flex;
                                    line-height: 24px;
                                    margin: 0;
                                    &:hover {
                                        background: color(grey, ExtraSoftGrey);
                                        text-decoration: none;
                                    }
                                    strong {
                                        font-weight: 600;
                                        margin-right: 5px;
                                        line-height: 22px;
                                    }
                                    ._price {
                                        margin-left: auto;
                                        padding-left: 10px;
                                    }
                                }
                            }
                        }
                    }
                    .cart__totals {
                        padding: 10px;
                        font-size: 13px;
                        ._totals {
                            li {
                                display: flex;
                                line-height: 24px;
                                font-weight: 500;
                                span {
                                    &:last-child {
                                        margin-left: auto;
                                    }
                                    &._free {
                                        color: color(alert, Success);
                                    }
                                }
                                &._grand {
                                    font-weight: 700;
                                    font-size: 15px;
                                }
                            }
                        }
                        ._actions {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-end;
                            margin: 10px 0 0;
                            a {
                                height: 40px;
                                line-height: 40px;
                                &._continue {
                                    font-size: 13px;
                                    margin-right: 20px;
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
