.checkout-success-page {
    padding: 30px 0;
    @include breakpoint(small only) {
        padding: 15px 0;
    }
    .breadcrumbs {
        display: none;
    }
    h1 {
        margin: 0 0 10px;
        font-size: 26px;
        @include breakpoint(small only) {
            font-size: 22px;
        }
    }
    .order__information {
        margin: 10px 0 0;
        strong {
            font-weight: 600;
            display: block;
            margin: 0 0 5px;
        }
        .order__address {
            margin: 20px 0 0;
            background: color(base, WhiteSecond);
            padding: 20px;
            h4 {
                margin: 0 0 10px;
                font-weight: 500;
            }
            ul {
                li {
                    font-size: 15px;
                    line-height: 25px;
                }
            }
        }
    }
}
