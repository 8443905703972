.customer-account {
    padding-top: 30px;
    padding-bottom: 30px;
    @include breakpoint(small only) {
        padding-top: 0;
        padding-bottom: 0;
    }
    h1, h2, h3 {
        display: flex;
        font-size: 23px;
        line-height: 28px;
        align-items: center;
        margin: 0 0 20px;
        @include breakpoint(small only) {
            font-size: 18px;
        }
    }
    h2 {
        font-size: 20px;
    }
    h3 {
        font-size: 16px;
        &:after {
            display: none;
        }
    }
    .breadcrumbs {
        display: none;
    }
    .customer__account-navigation_toggle {
        padding-left: 32px !important;
        @include default-button;
        background-repeat: no-repeat;
        background-position: left 8px center;
        background-size: 16px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-menu-2' width='20' height='20' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cline x1='4' y1='6' x2='20' y2='6' /%3E%3Cline x1='4' y1='12' x2='20' y2='12' /%3E%3Cline x1='4' y1='18' x2='20' y2='18' /%3E%3C/svg%3E");
        margin: 0 0 10px;
    }
    .customer__account-navigation {
        @include breakpoint(medium) {
            background: color(base, WhiteSecond);
            padding: 20px;
        }
        h3 {
            margin: 0 0 10px;
        }
        ul li {
            @include breakpoint(medium) {
                a {
                    font-size: 13px;
                    display: block;
                    line-height: 28px;
                }
            }
            @include breakpoint(small only) {
                font-size: .8125rem;
                line-height: 48px;
                padding: 0 .9375em;
                border-bottom: 1px solid color(grey, SoftGrey);
            }
            &._active {
                a {
                    font-weight: 700;
                    pointer-events: none;
                }
            }
        }
    }
    ._date {
        margin-left: auto;
        line-height: 34px;
        font-size: 12px;
    }
    ._new-address {
        margin-left: auto;
    }
    .field__container.flex {
        label {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin: 0 15px 0 0;
        }
        input {
            margin-right: 10px;
        }
    }
    .customer__block {
        .a-center {
            text-align: center;
        }
        .a-right {
            text-align: right;
        }
        table {
            width: 100%;
            margin: 0 0 20px;
            tr {
                &:nth-child(even) {
                    background: #FAFAFA;
                }
                th {
                    text-align: left;
                    font-weight: 500;
                }
                th, td {
                    padding: 4px 2px;
                    ul li {
                        font-size: 11px;
                    }
                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
        &._reviews table {
            td {
                &:first-child {
                    width: 20%;
                }
                &:last-child {
                    width: 40%;
                }
            }
        }
    }
    .order__totals {
        ul {
            margin-left: auto;
            li {
                display: flex;
                span, strong {
                    display: block;
                    flex: 1;
                    text-align: right;
                    font-size: 14px;
                    line-height: 24px;
                    &:first-child {
                        width: 140px;
                    }
                }
                strong {
                    font-size: 16px;
                }
            }
        }
    }

    .customer__account_block {
        &._login {
            background: color(base, WhiteSecond);
            padding: 20px;
            margin-bottom: 15px;
            ._forget {
                font-size: 13px;
                font-weight: normal;
                margin-left: 10px;
            }
        }
        ._usp {
            margin: 20px 0;
            h3 {
                margin: 0 0 10px;
                text-transform: initial;
            }
            li {
                line-height: 30px;
                color: color(base, Black);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-check' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, Green)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M5 12l5 5l10 -10' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: left center;
                padding-left: 25px;
                font-size: 13px;
                @include breakpoint(small only) {
                    font-size: 12px;
                }
            }
        }
    }
    ._intro {
        margin: 0 0 20px;
    }
    ._header {
        display: flex;
        align-items: center;
        margin: 10px 0;
        a {
            margin-left: auto;
            font-size: 13px;
            text-decoration: underline;
        }
        h2, h3 {
            margin: 0;
        }
    }
    .address__block {
        background: color(base, WhiteSecond);
        padding: 10px;
        margin: 0 0 15px;
        > span {
            font-size: 14px;
            line-height: 24px;
        }
        address {
            font-size: 14px;
            line-height: 22px;
        }
        > a {
            display: block;
            font-size: 13px;
            margin-top: 10px;
        }
        ._actions {
            display: flex;
            margin: 10px 0 0;
            a {
                display: block;
                font-size: 13px;
                margin-right: 10px;
                text-decoration: underline;
            }
        }
    }
}
