.hotspot__container {
    position: relative;
    display: inline-flex;
    picture {
        display: flex;
        width: 100%;
        height: 500px;
        img {
            width: 100%;
            max-height:500px;
            object-fit: cover;
        }
    }
    .hotspot__item {
        position: absolute;
        .hotspot__item__content {
            button {
                cursor: pointer;
                display: flex;
                justify-content: center;
                width: 38px;
                height: 38px;
                background-color: color(base, LightBlue);
                border: 2px solid color(base, White);
                color: color(base, White);
                border-radius: 50%;
                font-size: 34px;
                line-height: 1;
                z-index: 1;
                position: relative;
                transition: height 0.2s, width 0.2s, line-height 0.2s, font-size 0.2s, margin 0.2s, transform 0.1s;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(base, White)}'%3E%3Cpath fill-rule='evenodd' d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z' clip-rule='evenodd' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 26px;
                &.--small {
                    width: 28px;
                    height: 28px;
                    line-height: 1;
                    font-size: 26px;
                    border-width: 2px;
                    background-size: 20px;
                    + .hotspot__item__detail {
                        margin: 15px;
                    }
                }
                &:hover, &.--active {
                    -webkit-animation: 0;
                    animation: 0;
                    transform: scale(1.2);
                    z-index: 3;
                    &.--active {
                        transform: rotate(45deg) scale(1.2);
                    }
                }
            }
            .hotspot__item__detail {
                padding: 10px;
                border: 1px solid color(grey, ExtraSoftGrey);
                background: color(base, White);
                position: absolute;
                width: 180px;
                z-index: 2;
                margin: 20px;
                top: 0;
                left: 0;
                -webkit-box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.4);
                box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.4);
                @include breakpoint(medium) {
                    width: 325px;
                    display: flex;
                    gap: 20px;
                    align-items: center;
                }
                &.--right {
                    left: auto;
                    right: 0;
                }
                &.--bottom {
                    top: auto;
                    bottom: 0;
                }
                .image__holder {
                    @include breakpoint(medium) {
                        width: 104px;
                    }
                    img {
                        aspect-ratio: 1;
                        object-fit: scale-down;
                        width: 100%;
                        height: 100%;
                    }
                }
                .item__details {
                    a:not(._button) {
                        font-size: 14px;
                        line-height: 16px;
                        display: block;
                        margin: 0 0 10px;
                    }
                    .price__holder {
                        display: flex;
                        gap: 5px;
                        span {
                            display: block;
                            line-height: 28px;
                            font-size: 13px;
                            &.price__old-price {
                                text-decoration: line-through;
                            }
                            &.price {
                                font-weight: 700;
                            }
                        }
                    }
                    ._button {
                        margin: 10px 0 0;
                        line-height: 26px;
                        height: 28px;
                        font-size: 13px;
                        @include breakpoint(medium) {
                            line-height: 30px;
                            height: 32px;
                        }
                    }
                }
            }
        }
    }
    > a {
        position: absolute;
        inset: auto 20px 20px auto;
    }
}
