.kiyoh-container {
    a:hover {
        text-decoration: none;
    }

    .kiyoh-container-header {
        font-weight: bold;
    }
}

.review-banner {
    text-align: center;
    display: flex;
    flex-direction: column;

    .logo {
        margin-bottom: 50px;
        flex: 125px 0 0;
        display: inline;
    }
}

.review-footer {
    position: relative;

    .review {
        position: relative;
    }

    &-wrapper {
        background: color(base, NavPrimary);
        display: flex;
        padding-top: 50px;
        padding-bottom: 30px;
        min-height: 350px;
        flex-direction: column;
        margin-bottom: 2rem;

        h2 {
            text-align: center;
            font-size: 2.2rem;
            margin-bottom: 30px !important;
        }
    }

    .swiper-container {
        margin: 0 50px;
    }

    .slider__controls .slide-button {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
        background-size: auto;
        background-repeat: no-repeat;

        &:after {
            content: '';
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        transform: rotate(180deg);
        height: 35px;
        width: 35px;
        display: inline-block;
    }

    .swiper-button-next {
        position: absolute;
        right: -10px;
    }

    .swiper-button-prev {
        position: absolute;
        left: -10px;
    }

    .swiper-button-next {
        transform: unset !important;
    }

    div[aria-hidden="true"] {
        display: none !important;
    }
}

.review-stars {
    &-banner {
        position: relative;
        display: inline-block;
        bottom: 5px;
        width: 135px;
    }

    &-wrapper {
        position: absolute;
        left: 10px;
        bottom: 16px;
        width: 135px;
    }

    color: color(base, Black);
    font-size: 12px;
    font-weight: 900;
    position: relative;
    height: 25px;
    background-image: url(../assets/images/kiyoh-reviews/review_stars.svg);
    display: inline-block;
    background-size: contain;
    background-repeat: repeat-x;
}

.review-container {
    max-height: 160px;
    height: 160px;
    display: block;
    background: white;
    border-radius: 10px;
    width: 100%;

    .review {
        height: 100%;
    }
}

.review-date {
    max-width: 97%;
    position: relative;
    left: 16px;
    top: 16px;
    height: 25px;
    display: inline-block;
    font-weight: 900;
    color: color(base, Black);
}

.review-shop {
    position: absolute;
    right: 10px;
    bottom: 15px;
    color: color(base, Black);
    font-size: 12px;
    font-weight: 900;
}

.review-content {
    font-size: 1em;
    -webkit-box-orient: vertical;
    overflow: auto;
    padding: 0 16px;
    margin-top: 20px;
    color: color(base, Black);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    min-height: 65px;
    max-height: 130px;
    text-decoration: none;
    white-space: pre-line;
}

.review-footer {
    .review-footer {
        height: 160px;
        margin-right: 35px !important;

        > div {
            display: flex;
            justify-content: center;
            height: 100%;
        }
    }
}

.average-rating-container {
    display: inline-block;
    background-image: url(../assets/images/kiyoh-reviews/kiyoh-shape.svg);
    background-size: cover;
    flex: 75px 0 0;
    height: 75px;
    position: relative;
    color: color(base, Black);

    .rating {
        display: block;
        position: absolute;
        font-weight: 500;
        font-size: 1.5em;
        top: 50%;
        left: 0;
        width: 100%;
        margin: -0.55em 0 0 -0.04em;
        letter-spacing: -.05em;
        text-align: center;
    }
}

span {
    &.white-space {
        white-space: pre;
    }
}

.review-total {
    flex-wrap: wrap;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    color: color(base, Black);
    margin-top: 32px;
    font-size: 0.8em;

    .totals {
        font-weight: 600;
        text-decoration: underline;
    }

    @include breakpoint(small only) {
        .logo {
            margin-bottom: 0;

            &-container {
                flex-basis: 100%;
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .review-footer-wrapper h2 {
        font-size: 1.4375rem;
        line-height: 32px;
    }
    .review-total {
        font-size: .875rem;
        line-height: 23px;
    }
}

@media screen and (min-width: 1382px) {
    .review-footer {
        width: 70%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 500px) {
    .slider__controls .slide-button.swiper-button-prev {
        left: 10px;
        z-index: 100;
    }
    .slider__controls .slide-button.swiper-button-next {
        right: 10px;
        z-index: 100;
    }

    .review-total {
        padding: 0 16px;
    }
    .review-content {
        margin-top: 26px;
    }
    .footer-review-wrapper h2 {
        line-height: 2.2rem;
    }
}

@media screen and (min-width: 500px) and (max-width: 811px) {
    .review-footer {
        width: 100%;
    }
    .slider__controls .slide-button.swiper-button-prev {
        left: 10px;
        z-index: 100;
    }
    .slider__controls .slide-button.swiper-button-next {
        right: 10px;
        z-index: 100;
    }
}

@media screen and (min-width: 811px) and (max-width: 1381px) {
    .slider__controls .slide-button.swiper-button-prev {
        left: 10px;
        z-index: 100;
    }
    .slider__controls .slide-button.swiper-button-next {
        right: 10px;
        z-index: 100;
    }
}

.storepage__content {
    .review-footer-wrapper {
        margin-bottom: 0;
    }
}
