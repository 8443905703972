@font-face {
    font-family: 'Graphik LCG';
    src: url('/area/web/default/assets/fonts/GraphikLCG-Regular.eot');
    src: local('Graphik LCG Regular'), local('GraphikLCG-Regular'),
    url('/area/web/default/assets/fonts/GraphikLCG-Regular.eot?#iefix') format('embedded-opentype'),
    url('/area/web/default/assets/fonts/GraphikLCG-Regular.woff2') format('woff2'),
    url('/area/web/default/assets/fonts/GraphikLCG-Regular.woff') format('woff'),
    url('/area/web/default/assets/fonts/GraphikLCG-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('/area/web/default/assets/fonts/GraphikLCG-Medium.eot');
    src: local('Graphik LCG Medium'), local('GraphikLCG-Medium'),
    url('/area/web/default/assets/fonts/GraphikLCG-Medium.eot?#iefix') format('embedded-opentype'),
    url('/area/web/default/assets/fonts/GraphikLCG-Medium.woff2') format('woff2'),
    url('/area/web/default/assets/fonts/GraphikLCG-Medium.woff') format('woff'),
    url('/area/web/default/assets/fonts/GraphikLCG-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('/area/web/default/assets/fonts/GraphikLCG-Semibold.eot');
    src: local('Graphik LCG Semibold'), local('GraphikLCG-Semibold'),
    url('/area/web/default/assets/fonts/GraphikLCG-Semibold.eot?#iefix') format('embedded-opentype'),
    url('/area/web/default/assets/fonts/GraphikLCG-Semibold.woff2') format('woff2'),
    url('/area/web/default/assets/fonts/GraphikLCG-Semibold.woff') format('woff'),
    url('/area/web/default/assets/fonts/GraphikLCG-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('/area/web/default/assets/fonts/GraphikLCG-Bold.eot');
    src: local('Graphik LCG Bold'), local('GraphikLCG-Bold'),
    url('/area/web/default/assets/fonts/GraphikLCG-Bold.eot?#iefix') format('embedded-opentype'),
    url('/area/web/default/assets/fonts/GraphikLCG-Bold.woff2') format('woff2'),
    url('/area/web/default/assets/fonts/GraphikLCG-Bold.woff') format('woff'),
    url('/area/web/default/assets/fonts/GraphikLCG-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('/area/web/default/assets/fonts/GraphikLCG-Extralight.eot');
    src: local('GraphikLCG-Extralight'), local('Graphik LCG Extralight'),
    url('/area/web/default/assets/fonts/GraphikLCG-Extralight.eot?#iefix') format('embedded-opentype'),
    url('/area/web/default/assets/fonts/GraphikLCG-Extralight.woff2') format('woff2'),
    url('/area/web/default/assets/fonts/GraphikLCG-Extralight.woff') format('woff'),
    url('/area/web/default/assets/fonts/GraphikLCG-Extralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Run Wild';
    src: url('/area/web/default/assets/fonts/RunWild.eot');
    src: local('Run Wild'), local('RunWild'),
    url('/area/web/default/assets/fonts/RunWild.eot?#iefix') format('embedded-opentype'),
    url('/area/web/default/assets/fonts/RunWild.woff2') format('woff2'),
    url('/area/web/default/assets/fonts/RunWild.woff') format('woff'),
    url('/area/web/default/assets/fonts/RunWild.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

