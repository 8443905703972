#offCanvas-filter.is-open {
    width: 100%;
    overflow: hidden;
}

.catalog__category-filter {
    @include breakpoint(large) {
        max-width: 80%;
    }
    @include breakpoint(small only) {
        .modal__header {
            position: relative;
            height: 50px;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='21px' height='21px' viewBox='0 0 21 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EGroup 2%3C/title%3E%3Cdefs%3E%3Crect id='path-1' x='0' y='0' width='334' height='680'%3E%3C/rect%3E%3C/defs%3E%3Cg id='-' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Navigatie-mobiel' transform='translate(-26.000000, -23.000000)'%3E%3Cg id='Group-5'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Rectangle' fill='%23FFFFFF' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3Cg id='Group-2' transform='translate(26.000000, 41.000000)'%3E%3C/g%3E%3Cg id='Group-2' transform='translate(26.000000, 23.000000)' fill='%23DE2C4C'%3E%3Cpolygon id='Fill-1' points='2.7776 15.8508 7.5850437e-14 20.0228 13.5058 20.0228 16.282 15.8508'%3E%3C/polygon%3E%3Cpolygon id='Fill-2' points='6.95954 3.31068506e-13 4.18194 4.172 17.68634 4.172 20.46254 3.31068506e-13'%3E%3C/polygon%3E%3Cpolygon id='Fill-3' points='15.05532 12.20576 2.77732 12.20576 5.55632 8.03376 17.83292 8.03376'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: left 15px center;
            display: block;
            line-height: 50px;
            text-align: center;
            border-top: 7px solid color(base, LightBlue);

            .title {
                text-align: center;
                font-size: 14px;
                font-weight: normal !important;
            }

            .close-button {
                > span {
                    display: block;
                    width: 15px;
                    height: 15px;
                }

                display: block;
                width: 15px;
                height: 15px;
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EX%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='X' transform='translate(1.000000, 1.000000)' stroke='%23303030' stroke-width='2'%3E%3Cline x1='5.35924893e-14' y1='-1.33981223e-14' x2='14' y2='14' id='Line'%3E%3C/line%3E%3Cpath d='M0,14 L14,0 L0,14 Z' id='Line'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                top: 50% !important;
                transform: translateY(-50%);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: left center;

                &._back {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='18px' viewBox='0 0 11 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow down 2%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='02-Reclamed_Categorie_-Prijs_MOBILE-V4' transform='translate(-15.000000, -19.000000)' fill='%23303030' fill-rule='nonzero'%3E%3Cg id='Top'%3E%3Cpolygon id='Arrow-down-2' points='26 20.6090909 24.3316195 19 15 28 24.3316195 37 26 35.3909091 18.3367609 28'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    margin: 0 0 0 15px;
                }
            }
        }
        .modal {
            display: flex;
            flex-direction: column;
            height: 100%;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
        }
        .modal__content {
            height: 100%;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            @include breakpoint(medium up) {
                padding: 0 10px;
            }
        }
        .modal__footer {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 6;
            height: 60px;
            background: color(base, White);
            padding: 10px;
            text-align: center;

            ._cta {
                height: 40px;
                width: 100%;
                line-height: 40px;
                border-radius: 0;

                span {
                    color: color(base, White);
                }
            }
        }
    }

    .toolbar__active-filters {
        display: flex;
        margin: 25px 0 10px;
        h3 {
            text-transform: lowercase;
            font-size: 16px;
            line-height: 19px;
            font-weight: 700;
        }

        span {
            margin-left: auto;
            cursor: pointer;
            line-height: 19px;
            font-size: 14px;
            text-decoration: underline;
            &:hover {
                color: color(grey, Black);
            }
        }
        @include breakpoint(small only) {
            display: none;
        }
    }

    dl {
        @include breakpoint(medium up) {
            margin: 0 0 10px;
        }
        dt {
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            color: color(grey, Black);
            line-height: 36px;
            margin: 0 0 5px;
            display: flex;
            align-items: center;


            @include breakpoint(small only) {
                padding: 0 10px;
                line-height: 49px;
                border-top: none;
                border-bottom: 1px solid color(grey, ExtraSoftGrey);
                font-size: 14px;
                font-weight: normal;
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='9px' height='16px' viewBox='0 0 9 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EShape%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Shape' stroke='%23333333' stroke-width='0.5' fill='%23333333' fill-rule='nonzero' transform='translate(4.719094, 8.044669) rotate(-90.000000) translate(-4.719094, -8.044669) ' points='4.71909276 11.7637628 5.31414637 11.2454903 11.7637628 5.71724413 10.5736556 4.32557421 4.71909276 9.34515779 -1.13546698 4.32557421 -2.32557421 5.71724413 4.12403915 11.2454903'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: right 10px center;
            }
        }

        dd {
            @include breakpoint(small only) {
                transition: transform .15s linear;
                display: block;
                margin: 0;
                padding: 0;
                top: 0;
                z-index: 400;
                position: fixed;
                bottom: 60px;
                background: color(base, White);
                width: 100%;
                min-height: auto;
                left: 100%;
                overflow: scroll;
            }
            ol {
                li {
                    @include breakpoint(small only) {
                        padding: 0 15px;
                        border-bottom: 1px solid color(grey, SoftGrey);
                    }
                    label, a {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        line-height: 30px;
                        cursor: pointer;
                        color: color(grey, Black);
                        > label {
                            width: 100%;
                        }
                        @include breakpoint(small only) {
                            line-height: 49px;
                        }
                        &:hover {
                            ._checkbox {
                                border-color: color(grey, MediumGrey);
                            }
                        }
                        ._title {
                            overflow: hidden;
                            white-space: nowrap;
                        }
                        ._checkbox {
                            border: 1px solid color(grey, Black);
                            display: block;
                            width: 20px;
                            height: 20px;
                            border-radius: 2px;
                            margin-right: 8px;
                        }
                        ._count {
                            margin-left: auto;
                            color: color(grey, DarkerGrey);
                        }
                        &._active ._checkbox {
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='7px' viewBox='0 0 10 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EFill 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpolygon id='Fill-1' stroke='%23231F20' fill='%23FFFFFF' points='3.54581617 5.53783002 1.6370223 3.34424853 1 4.0737009 3.54581617 7 9 0.732064558 8.36525075 0'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }
                }

                &._swatch {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -15px;
                    @include breakpoint(small only) {
                        padding: 20px 0;
                    }
                    li {
                        width: 25%;
                        @include breakpoint(medium down) {
                            width: 33.33%;
                        }
                        @include breakpoint(small only) {
                            border-bottom: 0;
                        }
                        label {
                            display: block;
                            text-align: center;
                            padding: 3px 0;
                            ._color-container {
                                display: block;
                                text-align: center;
                                ._color {
                                    margin: 0 auto;
                                    display: block;
                                    width: 35px;
                                    height: 35px;
                                    border-radius: 50%;
                                    border: 1px solid transparent;
                                    &._white {
                                        border-color: color(grey, MediumGrey);
                                    }
                                    &:hover {
                                        border-color: color(grey, MediumGrey);
                                    }
                                }
                            }
                            ._title {
                                display: block;
                                font-size: 12px;
                            }
                            &._active ._color {
                                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='11px' viewBox='0 0 15 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='5.13132462 8.81921546 2.04945992 5.73735077 1 6.77942013 5.13132462 10.9107447 14 2.04206936 12.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='%23FFFFFF' fill='%23FFFFFF' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
                                background-repeat: no-repeat;
                                background-position: center;
                                &._white {
                                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='11px' viewBox='0 0 15 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='5.13132462 8.81921546 2.04945992 5.73735077 1 6.77942013 5.13132462 10.9107447 14 2.04206936 12.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='White'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='#{color(grey, Black)}' fill='#{color(grey, Black)}' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
                                }
                            }
                        }
                    }
                }
                &._columns {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        width: 50%;
                    }
                }
            }
            > a._button._secondary {
                height: 30px;
                line-height: 30px;
                font-size: 14px;
                margin: 15px 0 0;
                border-width: 1px;
                border-color: color(grey, MediumGrey);
                font-weight: normal;
                text-transform: lowercase;
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='6px' viewBox='0 0 11 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EFill 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M10.1854545,1.09236364 L5.38181818,5.89672727 C5.32,5.95854545 5.22690909,6 5.14472727,6 C5.06181818,6 4.96945455,5.95854545 4.90763636,5.89672727 L0.103272727,1.09236364 C0.0414545455,1.03054545 0,0.938181818 0,0.855272727 C0,0.773090909 0.0414545455,0.68 0.103272727,0.618181818 L0.618909091,0.103272727 C0.680727273,0.0414545455 0.762909091,0 0.856,0 C0.938181818,0 1.03127273,0.0414545455 1.09309091,0.103272727 L5.14472727,4.15418182 L9.19636364,0.103272727 C9.25745455,0.0414545455 9.35054545,0 9.43345455,0 C9.51563636,0 9.60872727,0.0414545455 9.67054545,0.103272727 L10.1854545,0.618181818 C10.2472727,0.68 10.2894545,0.773090909 10.2894545,0.855272727 C10.2894545,0.938181818 10.2472727,1.03054545 10.1854545,1.09236364' id='Fill-1' fill='%23909090'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
                background-position: right 10px center;
                padding: 0 30px 0 15px;
                &._chevron-up {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='6px' viewBox='0 0 11 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EFill 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M10.1854545,1.09236364 L5.38181818,5.89672727 C5.32,5.95854545 5.22690909,6 5.14472727,6 C5.06181818,6 4.96945455,5.95854545 4.90763636,5.89672727 L0.103272727,1.09236364 C0.0414545455,1.03054545 1.13686838e-13,0.938181818 1.13686838e-13,0.855272727 C1.13686838e-13,0.773090909 0.0414545455,0.68 0.103272727,0.618181818 L0.618909091,0.103272727 C0.680727273,0.0414545455 0.762909091,0 0.856,0 C0.938181818,0 1.03127273,0.0414545455 1.09309091,0.103272727 L5.14472727,4.15418182 L9.19636364,0.103272727 C9.25745455,0.0414545455 9.35054545,0 9.43345455,0 C9.51563636,0 9.60872727,0.0414545455 9.67054545,0.103272727 L10.1854545,0.618181818 C10.2472727,0.68 10.2894545,0.773090909 10.2894545,0.855272727 C10.2894545,0.938181818 10.2472727,1.03054545 10.1854545,1.09236364' id='Fill-1' fill='%23909090' transform='translate(5.144727, 3.000000) rotate(-180.000000) translate(-5.144727, -3.000000) '%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
                }
            }

            .price__filter {
                @include breakpoint(small only) {
                    box-sizing: border-box;
                    padding: 20px 20px;
                }
                .custom__controls {
                    display: flex;
                    input {
                        height: 32px;
                        line-height: 32px;
                        width: 60px;
                        padding: 0 5px;
                        text-align: center;
                        &:last-of-type {
                            margin-left: auto;
                        }
                    }
                }
                .vue-slider {
                    margin: 20px 5px 0;
                    .vue-slider-rail {
                        background-color: color(grey, SoftGrey);
                        .vue-slider-process {
                            background-color: color(grey, Black);
                        }
                        .vue-slider-dot-handle {
                            width: 23px;
                            height: 23px;
                            border-radius: 50%;
                            border: 2px solid color(grey, Black);
                            box-shadow: none;
                        }
                    }

                    .vue-slider-dot-tooltip .vue-slider-dot-tooltip-inner {
                        background: color(grey, Black);
                        border-color: color(grey, Black);
                        color: color(base, White);
                        span {
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 20px;
                            padding: 0 4px;
                        }
                    }
                }
            }
            .filter__actions {
                a {
                    display: inline-block;
                    font-size: 13px;
                    margin: 8px 0 0;
                    text-decoration: underline;
                }
            }
        }

        &.catalog__category-sub-categories {
            margin: 30px 0 20px;
            padding: 24px;
            background: color(primary, OffWhite);
            dt {
                font-size: 16px;
                line-height: 19px;
            }
            dd {
                ol {
                    li {
                        a {
                            position: relative;
                            &:hover, &._active {
                                font-weight: 600;
                                text-decoration: none;
                                &:before {
                                    display: block;
                                    content: '';
                                    width: 3px;
                                    background: color(primary, DarkBlue);
                                    position: absolute;
                                    left: -12px;
                                    top: 7px;
                                    bottom: 7px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &._visible {
            dd {
                @include breakpoint(medium up) {
                    display: block;
                }
                @include breakpoint(small only) {
                    transform: translateX(-100%);
                }
            }
            .modal__header {
                @include breakpoint(small only) {
                    font-size: 14px;
                    font-weight: 400;
                    color: color(grey, Black);
                }
            }
        }
    }
}
