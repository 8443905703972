.backdrop-overlay {
    display: none;
    transition: 0.2s;
    &._active {
        display: block;
        opacity: 0.2;
        width: 100%;
        height: 100%;
        background: color(base, Black);
        z-index: 2;
        position: fixed;
        top: 183px;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.main__navigation {
    background: color(base, White);
    position: relative;
    z-index: -1;
    .top__links {
        .opening__times {
            color: color(primary, DarkBlue);
            font-size: 14px;
            font-weight: 500;
            ._is-open {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: color(primary, CtaGreen);
                display: inline-block;
                top: -1px;
                position: relative;
                margin-left: 5px;
            }
            ._is-closed {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: color(primary, CtaOrange);
                display: inline-block;
                top: -1px;
                position: relative;
                margin-left: 5px;
            }
        }
    }
    nav {
        display: flex;
        position: relative;
        align-items: center;
        ul:nth-of-type(2) {
            margin-left: auto;
            li a.root-link {
                padding: 0 !important;
            }
        }
        > ul {
            display: flex;
            flex-wrap: wrap;
            li:first-of-type a {
                padding: 0 25px 0 0 !important;
            }
            > li {
                cursor: pointer;
                &:last-of-type {
                    a {
                        padding: 0;
                    }
                }
                > a {
                    position: relative;
                    display: block;
                    font-size: 16px;
                    padding: 0 25px;
                    line-height: 40px;
                    color: color(base, Black);
                    text-transform: inherit;
                    @include breakpoint(medium only) {
                        padding: 0 24px 0 0;
                    }
                }
                > .navigation-header {
                    padding: 0 25px;
                    font-weight: 600;
                    position: relative;
                    font-size: 14px;
                    height: 40px;
                    line-height: 40px;
                    @include breakpoint(medium only) {
                        padding: 0 24px 0 0;
                    }
                }
                > .group-header {
                    &:after {
                        content:'';
                        width: 14px;
                        height: 14px;
                        right: 5px;
                        top: 12px;
                        position: absolute;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 15.75l7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");
                        background-size: 14px;
                        background-repeat: no-repeat;
                        transform: rotate(180deg);
                    }
                    &:hover:after {
                        transform: rotate(0deg);
                    }
                }
                &:hover > a::after {
                    border-color: color(base, Black);
                }
            }
            .sale.root-link {
                color: color(alert, Warning);
                font-weight: 500;
            }
            .info {
                color: color(base, LightBlue);
                font-weight: 500;
            }
        }
        .root-element {
            > a:not(.sale, .root-link) {
                padding: 0 25px;
                font-weight: 500;
                position: relative;
                font-size: 14px;
                height: 40px;
                line-height: 40px;
                @include breakpoint(medium only) {
                    padding: 0 24px 0 0;
                }
                &:after {
                    content:'';
                    width: 14px;
                    height: 14px;
                    right: 5px;
                    top: 12px;
                    position: absolute;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 15.75l7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");
                    background-size: 14px;
                    background-repeat: no-repeat;
                    transform: rotate(180deg);
                }
                &:hover:after {
                    transform: rotate(0deg);
                }
                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 2px;
                    background: transparent;
                    left: 0;
                    bottom: 0;
                    position: absolute;
                }
                &:hover:before {
                    background: color(grey, SoftGrey);
                }
            }
            a {
                &:hover {
                    text-decoration: none;
                }
            }
            > .navigation-header, > a > .navigation-header {
                height: 40px;
                line-height: 40px;
                font-weight: 500;
                font-size: 14px;
                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 2px;
                    background: transparent;
                    left: 0;
                    bottom: 0;
                    position: absolute;
                }
                &:hover:before {
                    background: color(grey, SoftGrey);
                }
            }
            &.column {
                .navigation-group-items {
                    flex-direction: column;
                }
            }
            > .navigation-group-container {
                display: none;
                position: absolute;
                justify-content: space-between;
                top: 100%;
                left: 0;
                right: 0;
                border-top: 1px solid color(grey, SoftGrey);
                box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px;
                border-radius: 0 0 2px 2px;
                z-index: 751;
                background: color(base, NavPrimary);
                padding: 30px 20px;
                .navigation-group-items {
                    display: flex;
                    background: color(base, NavPrimary);
                    flex: 1;
                    flex-basis: 60%;
                    //padding: 20px;
                    flex-wrap: wrap;
                    border-radius: 0 0 0 5px;
                    &.group-images {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        width: 30%;
                        margin: 0;
                        gap: 20px;
                        border-radius: 0 0 5px 0;
                        li {
                            width: 100%;
                            padding: 0;
                            img {
                                width: 100%;
                                height: auto;
                                object-fit: cover;
                            }
                        }
                    }
                    > li {
                        width: 20%;
                        padding-right: 2rem;
                        ul {
                            li {
                                width: 100%;
                                padding: 0;
                            }
                        }
                        > a {
                            font-weight: 600;
                            font-size: 14px;
                            display: inline-block;
                            margin: 0 0 10px;
                        }
                        > ul {
                            margin: 0 0 1rem;
                            > li {
                                a {
                                    color: color(grey, Black);
                                    display: inline-block;
                                    line-height: 2.6rem;
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .non-root-element {
            .navigation-header {
                height: 14px;
                line-height: 14px;
            }
            > .navigation-group-container {
                .grid-container {
                    padding: 0;
                    margin: 0 0 1rem;
                    a {
                        font-weight: 400 !important;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                > .navigation-group-items {
                    margin: 0;
                    padding: 0;
                    flex-direction: column;
                    li {
                        display: block;
                        a {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}

